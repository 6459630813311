import { gql } from '@apollo/client';
export const CREATE_CAREPLAN = gql`
  mutation createCareplan($diagnosisId: ID, $patientId: ID) {
    createCareplan(diagnosisId: $diagnosisId, patientId: $patientId) {
      success
      careplan {
        id
        careplanName
        careplanStatus
        isPrimDiagnosisOwner
        isMetsDiagnosisOwner
        featureVersion
      }
    }
  }
`;

export const UPDATE_CAREPLAN_DIAGNOSIS = gql`
  mutation updateCareplanDiagnosis($careplanId: ID!, $diagnosisId: ID, $relatedPrimaryDiagnosisId: ID) {
    updateCareplanDiagnosis(
      careplanId: $careplanId
      diagnosisId: $diagnosisId
      relatedPrimaryDiagnosisId: $relatedPrimaryDiagnosisId
    ) {
      success
      careplan {
        id
      }
      diagnosis
    }
  }
`;

export const GET_CAREPLAN_SITE_GROUPS = gql`
  query siteGroupsByCareplan($careplanId: ID!) {
    siteGroupsByCareplan(careplanId: $careplanId) {
      id
      name
      selectionMethod
      template {
        id
        template {
          id
        }
      }
      imagingList {
        id
      }
      sitegroupvaluesSet {
        id
        field {
          id
          name
        }
        hasVaried
        value
      }

      siteSet {
        id
        treatmentSite {
          id
          treatmentSite
          defaultLaterality
        }
        template {
          id
        }
        sitevaluesSet {
          id
          field {
            id
            name
          }
          hasVaried
          value
        }
      }
    }
  }
`;

export const DELETE_SITE = gql`
  mutation deleteSite($id: ID!) {
    deleteSite(id: $id) {
      success
    }
  }
`;

export const LINK_SITE = gql`
  mutation linkSiteGroup($siteGroupId: ID!, $siteId: ID!) {
    linkSiteGroup(siteGroupId: $siteGroupId, siteId: $siteId) {
      success
    }
  }
`;

export const UNLINK_SITE = gql`
  mutation unlinkSite($siteId: ID!, $careplanId: ID!, $originalSiteGroupId: ID!) {
    unlinkSite(siteId: $siteId, careplanId: $careplanId, originalSiteGroupId: $originalSiteGroupId) {
      success
    }
  }
`;

export const CLEAN_SITE_GROUPS = gql`
  mutation cleanSiteGroups($careplanId: ID!) {
    cleanSiteGroups(careplanId: $careplanId) {
      success
    }
  }
`;

export const DELETE_SITE_GROUP = gql`
  mutation deleteSiteGroup($id: ID!) {
    deleteSiteGroup(id: $id) {
      success
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation updatePrescription($siteId: ID!, $laterality: String, $location: String) {
    updatePrescription(siteId: $siteId, laterality: $laterality, location: $location) {
      site {
        id
        location
      }
    }
  }
`;
export const UPDATE_GROUP_LATERALITY = gql`
  mutation updateGroupLaterality($siteGroupId: ID!, $siteId: ID!, $laterality: String!) {
    updateGroupLaterality(siteGroupId: $siteGroupId, siteId: $siteId, laterality: $laterality) {
      success
    }
  }
`;

export const CONDITIONAL_FIELD_LIST = gql`
  query conditionalFieldList {
    conditionalFieldList {
      id
      name
    }
  }
`;

export const UPDATE_SITE_VALUE = gql`
  mutation updateSiteValue($id: ID!, $siteId: ID!, $fieldName: String, $value: String!) {
    updateSiteValue(id: $id, siteId: $siteId, fieldName: $fieldName, value: $value) {
      siteValues {
        id
        hasVaried
      }
    }
  }
`;

export const UPDATE_SITE_GROUP_VALUE = gql`
  mutation updateSiteGroupValue($id: ID!, $siteGroupId: ID!, $fieldName: String, $value: String!) {
    updateSiteGroupValue(id: $id, siteGroupId: $siteGroupId, fieldName: $fieldName, value: $value) {
      siteGroupValue {
        id
        field {
          id
          name
        }
        value
      }
    }
  }
`;

export const GET_INTAKE_CODE_MAPPING = gql`
  query {
    intakeCodeMapping {
      id
      intake {
        id
        name
      }
      diagnosisCode {
        id
        diagnosisCode
      }
    }
  }
`;

export const UPDATE_CAREPLAN_LAST_PAGE = gql`
  mutation updateCareplan($id: ID!, $latestPageUrl: String) {
    updateCareplan(id: $id, latestPageUrl: $latestPageUrl) {
      careplan {
        id
        latestPageUrl
        featureVersion
      }
    }
  }
`;

export const SOFT_DELETE_CAREPLAN = gql`
  mutation softDeleteCareplan($id: ID!) {
    softDeleteCareplan(id: $id) {
      careplan {
        id
      }
    }
  }
`;

export const GET_CAREPLAN_STATUS = gql`
  query careplan($id: ID!) {
    careplan(id: $id) {
      id
      careplanStatus
    }
  }
`;

export const SUBMIT_PRESCRIPTION = gql`
  mutation submitPrescription($id: ID!, $status: String!) {
    submitPrescription(id: $id, status: $status) {
      careplan {
        id
        careplanStatus
      }
    }
  }
`;

export const SITE_GROUP_TEMPLATE_VALUE_VARIATION = gql`
  query siteGroupTemplateValueVariation($siteGroupId: ID!) {
    siteGroupTemplateValueVariation(siteGroupId: $siteGroupId) {
      variation
    }
  }
`;

export const SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION = gql`
  query siteTemplateValuesByGroupVariation($siteGroupId: ID!, $volumingPage: Boolean) {
    siteTemplateValuesByGroupVariation(siteGroupId: $siteGroupId, volumingPage: $volumingPage) {
      variation
    }
  }
`;

export const GET_CAREPLAN = gql`
  query careplan($id: ID!) {
    careplan(id: $id) {
      id
      careplanStatus
      treatingDepartment
      submittedAt
      simulationModification
      prescriptionModification
      diagnosisModification
      cied
      ciedPatientDependancy
      ciedLinacRequired
      changeType
      parent {
        id
        careplanStatus
        changeType
        submittedAt
      }
      practitioner {
        id
      }
      isPrimDiagnosisOwner
      isMetsDiagnosisOwner
      ajccMajorVersion
      featureVersion
    }
  }
`;

export const SITE_TEMPLATE_VALUES_IN_SITE_GROUP = gql`
  query siteGroupTemplate($siteGroupId: ID!) {
    siteGroupTemplate(id: $siteGroupId) {
      id
      treatmentsiteprescriptiontemplateSet {
        id
        treatmentrulesiteSet {
          id
          field {
            id
            name
          }
          defaultValue
        }
      }
    }
  }
`;

export const FREQUENTLY_USED_TEMPLATES = gql`
  query frequentlyUsedTemplates($patientId: ID!, $tumourStream: String!) {
    frequentlyUsedTemplates(patientId: $patientId, tumourStream: $tumourStream) {
      id
      name
      ranking
      approvedDate
    }
  }
`;

export const SIGMA_DATA = gql`
  query sigmaData($careplanId: ID!) {
    sigmaData(careplanId: $careplanId) {
      id
      responseUUID
      status
      validationErrors {
        field
        message
      }
      templates {
        id
        name
        score
      }
    }
  }
`;

export const USER_DATA = gql`
  query user {
    user {
      id
      isShowcaseUser
      isRegistrar
    }
  }
`;

export const TRIGGER_CPOT = gql`
  mutation updateCareplan($id: ID!, $careplanStatus: String, $cpotTriggered: Boolean) {
    updateCareplan(id: $id, careplanStatus: $careplanStatus, cpotTriggered: $cpotTriggered) {
      careplan {
        id
        careplanStatus
        cpotTriggered
      }
    }
  }
`;

export const UPDATE_TREATMENT_SITE = gql`
  mutation updateTreatmentSite($siteId: ID!, $treatmentSite: String) {
    updateTreatmentSite(siteId: $siteId, treatmentSite: $treatmentSite) {
      success
    }
  }
`;

export const SITE_NAME_VARIATION = gql`
  query siteNameVariation($careplanId: ID!) {
    siteNameVariation(careplanId: $careplanId) {
      variation
    }
  }
`;

export const UPDATE_SIM_SUBMISSION = gql`
  mutation ($id: ID!, $submitSimulation: Boolean) {
    updateSimulationSubmissionStatus(careplanId: $id, submitSimulation: $submitSimulation) {
      success
    }
  }
`;

export const GET_CAREPLAN_SITE_LIST = gql`
  query siteListByCareplan($careplanId: ID!) {
    siteListByCareplan(careplanId: $careplanId) {
      id
      laterality
      treatmentSite
    }
  }
`;

export const GET_QUESTION = gql`
  query questionByType($type: String!) {
    questionByType(formType: $type) {
      id
      type
      values
      isActive
    }
  }
`;

export const UPDATE_SPECIAL_PHYSICS_CONSULT = gql`
  mutation updateSpecialPhysicsConsult($siteGroupId: ID!, $value: String) {
    updateSpecialPhysicsConsult(siteGroupId: $siteGroupId, value: $value) {
      success
    }
  }
`;

export const DISCARD_CAREPLAN_CHANGES = gql`
  mutation discardCareplanChanges($id: ID!) {
    discardCareplanChanges(id: $id) {
      careplan {
        id
        careplanStatus
      }
    }
  }
`;
