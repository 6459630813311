import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import useInterval from 'shared-components/utils/useInterval';
import { Slide, toast } from 'react-toastify';
import { useErrorModalContext, UserContext } from 'op-contexts';
import { useRouteMatch } from 'react-router-dom';
import { Card, LoadingSpinner } from 'shared-components/components';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType, GCButtonSize } from 'shared-components/enums';
import NotesFilter from 'op-components/RO/Notes/NotesFilter';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { useOnClickOutside } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import { ADD_PATIENT_NOTE, UPDATE_PATIENT_NOTE, GET_PATIENT_PROFILE } from '../PatientProfileQueries';
import { useNoteTypes, usePatientNotes, PatientNote, GET_PATIENT_NOTES } from 'op-components/RO/Notes/Notes';
import { PAT_ID_1_REFRESH_TIME } from 'shared-components/enums';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { formatNoteCreationDate } from 'op-utils/helpers';
import { sanitizeNote } from 'op-pages/RO/Notes/sanitizeNote';
import { Stack, ButtonGroup, Button, useTheme } from '@mui/material';
import { GCButtonTextSize } from 'shared-components/components/FormFields/GCButton/GCButton';

const THERANOSTICS = ['THA', 'THA MQ'];

interface ROPatientNoteNavType {
  id: string;
}

const StyledBoxHeader = styled('h3')`
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  line-height: 2em;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: bold;
  width: 100%;
`;

const StyledQuickNotesHeader = styled('h3')`
  background: ${(props) => props.theme.palette.secondary.light};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.9em;
`;

const StyledSubmitCol = styled(Stack)`
  display: flex;
  background: ${(props) => props.theme.palette.secondary.light};
  height: 3.4em;
  align-items: center;
  justify-content: flex-end;
`;

const StyledColPractitionerName = styled(Stack)`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  overflow-wrap: anywhere;
  margin-top: 0;
  padding-right: 0;
`;

const StyledColNoteDate = styled(Stack)`
  font-size: 0.85rem;
  line-height: 1.25rem;
  height: calc(100% - 480px);
  color: ${(props) => props.theme.palette.text.primary};
  text-align: right;
  padding-left: 0;
`;

const StyledColNoteMessage = styled(Stack)`
  font-style: normal;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 15px 0 15px 0;
  word-break: break-word;
  padding-left: 16px;
  font,
  *,
  & {
    font-family: Arial, sans-serif !important;
    font-size: 1rem !important;
    color: black !important;
  }
`;

const StyledNoteType = styled('div')`
  background: ${(props) => props.theme.palette.secondary.light};
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 2px 8px 2px 8px;
  color: ${(props) => props.theme.palette.text.primary};
  margin-left: 16px;
  width: fit-content;
`;

const StyledColNote = styled(Stack)`
  margin: 1rem 0 0 0;
`;

const StyledColHr = styled(Stack)`
  content: '';
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  width: 100%;
`;

const EmptyContainer = styled('div')`
  padding: 16px;
`;

export const ToastNotificationMessage = (): JSX.Element => (
  <>
    <strong style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }}>New Note Added</strong>
    <p style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden', lineHeight: '14px' }}>
      View previous notes via the notes page on the left
    </p>
  </>
);

const ReadPatientNotes = (props: any): JSX.Element => {
  const { setError } = useErrorModalContext();
  const { notes, loading, error, filter, practitionerTimezone } = props;

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  if (loading)
    return (
      <Stack className="px-0">
        <LoadingSpinner relativeSpinner={true} loadingText={'Loading notes'} />
      </Stack>
    );

  if (!notes && !filter) return <div />;

  const createDate = (note: PatientNote) =>
    formatNoteCreationDate(note, practitionerTimezone, CurrentAppConfig.Notes.DateTimeFormat);

  if (notes && !notes.length) {
    return <EmptyContainer>No notes found.</EmptyContainer>;
  }

  return notes?.map((note: PatientNote) => (
    <StyledColNote className="quick-notes-note-option px-0 mt-1" key={note.id}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '8px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <StyledColPractitionerName>
          {note.createdBy?.name} {note.practitionerQualification && `(${note.practitionerQualification})`}
        </StyledColPractitionerName>
        <StyledColNoteDate>{createDate(note)}</StyledColNoteDate>
      </Stack>
      <Stack>
        {note.messageTypeAlias && <StyledNoteType data-test-id="note-heading">{note.messageTypeAlias}</StyledNoteType>}
      </Stack>
      <Stack>
        <StyledColNoteMessage
          style={{ whiteSpace: note.formattedMessage.startsWith('<!DOCTYPE html') ? 'normal' : 'pre-wrap' }}>
          <div
            data-test-id="note-content"
            id="note-content"
            dangerouslySetInnerHTML={sanitizeNote(note.formattedMessage)}
          />
        </StyledColNoteMessage>
      </Stack>
      <Stack>
        <StyledColHr></StyledColHr>
      </Stack>
    </StyledColNote>
  ));
};

const NoteTypeButtons = (props: any): JSX.Element => {
  const { noteTypes, selected, setSelectedNoteType } = props;
  const theme = useTheme();
  const noteTypesCopy = [...noteTypes];

  if (!noteTypes) return <div></div>;
  return (
    <ButtonGroup id="noteTypeButtons" aria-label="Basic example" sx={{ width: 'fit-content' }}>
      {noteTypes &&
        [...noteTypesCopy]
          .sort((a: any, b: any) => a.listWeight - b.listWeight)
          .map((note: any, index: number) => {
            const activeButton = selected === note.name;
            return (
              <Button
                key={index}
                data-test-id={`noteType-${index}`}
                onClick={() => {
                  setSelectedNoteType(note.name);
                }}
                variant="text"
                sx={{
                  textTransform: 'none',
                  color: 'darkslategrey',
                  fontSize: '1rem',
                  borderBottom: activeButton ? `4px solid ${theme.palette.primary.main}` : 'none',
                  paddingBottom: activeButton ? '8px !important' : '12px !important',
                  borderRadius: '0px',
                }}>
                <span>{note.alias ? note.alias : note.name}</span>
              </Button>
            );
          })}
    </ButtonGroup>
  );
};

const ROPatientNotes = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const { state } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [selectedNoteType, setSelectedNoteType] = useState<string | null>(null);
  const [draftNote, setDraftNote] = useState<any>();
  const [noteMessage, setNoteMessage] = useState<string>('');
  const match = useRouteMatch<ROPatientNoteNavType>();
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { id: patientId } = match.params;

  const { data: pracData } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
    skip: !patientId,
  });
  const practitionerTimezone = useMemo(
    () => pracData?.practitioner?.timezone || CurrentAppConfig.DefaultTimezone,
    [pracData?.practitioner?.timezone],
  );

  const { notes, filteredNotes, filterNoteTypes, setFilterNoteTypes, isLoading, hasErrors } =
    usePatientNotes(patientId);
  const noteTypes = useNoteTypes(patientId);
  const [updateNoteMutation] = useMutation(UPDATE_PATIENT_NOTE, {
    refetchQueries: [{ query: GET_PATIENT_NOTES, variables: { patientId } }],
  });

  const {
    data: patientProfileData,
    error: patientProfileError,
    refetch,
  } = useQuery(GET_PATIENT_PROFILE, {
    variables: { patientId },
  });

  // Refresh profile every few seconds until patient has pat_id_1
  useInterval(
    () => {
      refetch();
    },
    patientProfileData?.patient?.userProfile?.hasPatId1 ? null : PAT_ID_1_REFRESH_TIME,
  );

  useEffect(() => {
    if (hasErrors || patientProfileError) return setError();
  }, [hasErrors, patientProfileError]);

  useEffect(() => {
    if (noteTypes && noteTypes.length) {
      setSelectedNoteType(noteTypes[0].name);
    }
  }, [noteTypes]);

  const [addNote] = useMutation(ADD_PATIENT_NOTE, {
    refetchQueries: [{ query: GET_PATIENT_NOTES, variables: { patientId } }],
    onCompleted: (): void => {
      setNoteMessage('');
    },
  });

  useOnClickOutside(containerRef, () => open && setOpen(false));
  useEffect(() => {
    if (notes) {
      const filteredDraft = notes?.filter(
        (note: PatientNote) => note?.isDraft && note?.createdBy?.id === pracData?.practitioner?.id,
      )[0];
      setDraftNote(filteredDraft || null);
      scrollToNewNote();
    }
  }, [notes]);

  const submitNote = (message: string | null) => {
    if (message) {
      addNote({ variables: { patientId, messageType: selectedNoteType, message, noteId: draftNote?.id } });
      setNoteMessage('');
      state?.primaryRole === 'ro' &&
        toast.dark(<ToastNotificationMessage />, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide,
          progress: undefined,
        });
    }
  };

  const scrollToNewNote = () => {
    const notes = document.getElementsByClassName('quick-notes-note-option');
    if (typeof notes[0] !== 'undefined') {
      notes[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { relatedTarget }: any = e;
    const idsToBlockUpdate = ['submitQuickNote', 'note-type-button'];
    if (!idsToBlockUpdate.includes(relatedTarget?.id) && selectedNoteType) {
      updateNoteMutation({
        variables: { patientId, message: e.target.value, messageType: selectedNoteType, noteId: draftNote?.id },
      });
    }
  };

  const hasPatId1 = useMemo<boolean>(() => patientProfileData?.patient?.userProfile?.hasPatId1, [patientProfileData]);
  const isTheranostics = useMemo<boolean>(
    () => THERANOSTICS.includes(patientProfileData?.patient?.emrInstance),
    [patientProfileData],
  );
  const disableNotes = useMemo<boolean>(
    () => !state.hasStaffId || !hasPatId1 || isTheranostics,
    [state, hasPatId1, isTheranostics],
  );

  return (
    <Card $noPadding $filled style={{ display: 'flex', flexDirection: 'column' }} ref={containerRef}>
      <Stack sx={{ height: '100%' }}>
        <Stack sx={{ height: '50%' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ padding: '4px 8px' }}>
            <StyledBoxHeader>Notes</StyledBoxHeader>
            <NotesFilter noteTypes={filterNoteTypes} setFilterNoteTypes={setFilterNoteTypes} />
          </Stack>
          <Stack sx={{ overflow: 'auto' }}>
            <ReadPatientNotes
              notes={filteredNotes}
              loading={isLoading}
              error={hasErrors}
              filter={filterNoteTypes}
              practitionerTimezone={practitionerTimezone}
            />
          </Stack>
        </Stack>
        <Stack sx={{ height: '50%' }}>
          <StyledQuickNotesHeader>Quick notes</StyledQuickNotesHeader>
          {!disableNotes && (
            <Stack className="quick-notes-col" style={{ width: '60px' }}>
              <NoteTypeButtons
                noteTypes={noteTypes}
                selected={selectedNoteType}
                setSelectedNoteType={setSelectedNoteType}
              />
            </Stack>
          )}
          {/* Using base textarea here as MUI ones don't autosize width and height to fill component nicely */}
          <textarea
            id="quickNotesInput"
            disabled={disableNotes}
            placeholder={disableNotes ? 'Please add notes in Mosaiq.' : 'Please add notes here.'}
            value={noteMessage || ''}
            onChange={(e: any): void => {
              setNoteMessage(e.target.value || '');
            }}
            onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
              const timeout = setTimeout(() => handleBlur(event), 400);
              setTimeoutId(timeout);
            }}
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              height: '100%',
              width: '100%',
              fontFamily: 'unset',
              border: 'unset',
            }}
          />
          <StyledSubmitCol direction="row" sx={{ alignItems: 'flex-end', padding: '4px 4px' }}>
            <GCButton
              id="submitQuickNote"
              dataTestId="submitQuickNote"
              title="Add note"
              onClick={(): void => {
                clearTimeout(timeoutId);
                setTimeoutId(null);
                submitNote(noteMessage);
              }}
              textSize={GCButtonTextSize.SMALLER}
              buttonSize={GCButtonSize.SMALL}
              disabled={!noteMessage || disableNotes}
              type={noteMessage === null ? ButtonType.DISABLED : ButtonType.GREEN}
            />
          </StyledSubmitCol>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ROPatientNotes;
