import { gql } from '@apollo/client';

export const ON_TREATMENT_REVIEW_MUTATION = gql`
  mutation createTreatmentReview(
    $patientId: ID!
    $reviewDate: Date!
    $careplan: String!
    $treatmentFractionsReviewed: String!
    $responseToTreatment: String!
    $imageReview: String!
    $managementDecision: String!
    $clinicalExamination: String!
    $dss: JSONString!
  ) {
    createTreatmentReview(
      patientId: $patientId
      reviewDate: $reviewDate
      careplan: $careplan
      treatmentFractionsReviewed: $treatmentFractionsReviewed
      responseToTreatment: $responseToTreatment
      imageReview: $imageReview
      managementDecision: $managementDecision
      clinicalExamination: $clinicalExamination
      dss: $dss
    ) {
      treatmentReview {
        id
      }
    }
  }
`;
