import React, { useState } from 'react';
import { DatePicker, DatePickerProps, DateValidationError } from '@mui/x-date-pickers';
import BaseTextField from '../ROTextField/BaseTextField';
import { getBorderColor } from '../common';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTheme } from '@mui/material';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const getDateErrorMessage = (error: DateValidationError | null): string => {
  switch (error) {
    case 'maxDate':
      return 'Date is above the maximum date';
    case 'minDate':
      return 'Date is below the minimum date';
    case 'disableFuture':
      return 'Date cannot be in the future';
    case 'disablePast':
      return 'Date cannot be in the past';
    case 'invalidDate':
      return 'Please select a valid date';
    default:
      return '';
  }
};

export interface Props extends DatePickerProps<Dayjs> {
  id: string;
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  helperText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}

const BaseDatePicker = ({ id, error, warning, info, helperText, onError, ...rest }: Props): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const hasError = !!errorMessage || error;
  const borderColor = getBorderColor(hasError, warning, info);
  const theme = useTheme();

  const inputHelperText = (): string | undefined => {
    if (errorMessage) return errorMessage;
    if (helperText) return helperText;
    if (error) return 'This field is required';
    return undefined;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={CurrentAppConfig.DefaultLocale}>
      <DatePicker
        sx={{
          '&:hover .MuiOutlinedInput-notchedOutline': {
            'border-color': `${hasError ? theme.palette.error.main : theme.palette.primary.main} !important`,
          },
          '.MuiSvgIcon-root': {
            outlineColor: theme.palette.primary.main,
            color: hasError ? theme.palette.error.main : theme.palette.primary.main,
          },
        }}
        slots={{ textField: BaseTextField }}
        slotProps={{
          textField: {
            onBlur: (e) => {
              rest.onBlur && rest.onBlur(e);
            },
            error: hasError,
            helperText: inputHelperText(),
            // @ts-expect-error Doesn't work nicely with custom components
            warning,
            info,
            id,
            sx: {
              '.MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${borderColor} !important`,
              },
              ':hover .MuiOutlinedInput-notchedOutline': {
                borderColor,
              },
            },
          },
          openPickerButton: {
            sx: {
              outline: 'none !important',
            },
          },
          actionBar: {
            actions: ['clear'],
          },
        }}
        views={['year', 'month', 'day']}
        onError={(error: DateValidationError, value: Dayjs | null) => {
          setErrorMessage(getDateErrorMessage(error));
          onError && onError(error, value);
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default BaseDatePicker;
