// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';

import { ROSidePanel, RODoseSiteCarousel } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { groupBy } from 'op-utils';
import { GeneralError, LoadingSpinner } from 'shared-components/components';

import { CurrentAppConfig } from '../AppConfig';
import { CAREPLAN_PAGES, NO_TEMPLATES_MESSAGE } from '../Constants';
import { GET_DIAGNOSIS_FROM_CAREPLAN, GET_PEER_REVIEW } from './SidePanelQueries';
import ROPatientDiagnosisCurrentCode from '../DiagnosisPages/CurrentCode';
import { GET_CAREPLAN_SITE_GROUPS, GET_CAREPLAN, DELETE_SITE_GROUP } from '../Queries';
import { getSiteRepresentation, extractValueFromSite, templatesWithValues } from '../Common';
import { GET_DOSE_SITE_SUMMARIES } from '../../PatientSummary/PatientProfileQueries';
import './SidePanel.scss';
import ROPeerReviewSidePanel from './PeerReviewSidePanel';
import { Stack, Tooltip, useTheme } from '@mui/material';
import SidePanelItem from './SidePanelItem';
import { ArrowRight, DeleteOutline } from '@mui/icons-material';

const ROPatientCarePathDiagnosisWidget = (): JSX.Element => {
  const match: { params: { careplanId: string } } = useRouteMatch();
  const { careplanId } = match.params;
  const { setError } = useErrorModalContext();
  const { loading, error, data } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, { variables: { careplanId: careplanId } });
  useEffect(() => {
    if (error) return setError();
  }, [error]);
  if (loading) return <div>Loading</div>;
  if (!data) return <div />;
  const { diagnosis } = data.careplan;
  const primaryDiagnosisCode = diagnosis.isPrimaryDiagnosis ? diagnosis : diagnosis.relatedPrimaryDiagnosis;
  const secondaryDiagnosisCode = diagnosis.isPrimaryDiagnosis ? null : diagnosis;
  return (
    <>
      {primaryDiagnosisCode && (
        <ROPatientDiagnosisCurrentCode
          showTitle={true}
          showExpandButton={true}
          diagnosisCodeSet={primaryDiagnosisCode}
          showPrimary={true}
        />
      )}
      {secondaryDiagnosisCode && (
        <ROPatientDiagnosisCurrentCode showTitle={true} diagnosisCodeSet={secondaryDiagnosisCode} showPrimary={false} />
      )}
    </>
  );
};

const ROPatientCarePathCarePlanSitesWidget = (): JSX.Element => {
  const match: { params: { id: string; careplanId: string; siteGroupIdx: string; siteIdx: string } } = useRouteMatch();
  const { careplanId, siteGroupIdx, siteIdx } = match.params;
  const pathLocation = useLocation().pathname;
  const { setError } = useErrorModalContext();
  const theme = useTheme();
  const { loading, error, data } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    variables: { careplanId },
  });
  const [hiddenGroups, setHiddenGroups] = useState<any>([]);
  const [deleteSite] = useMutation(DELETE_SITE_GROUP, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  useEffect((): void => {
    const activePanelElements = document.getElementsByClassName('sidepanel-container active');
    if (!activePanelElements || !activePanelElements.length) return;
    activePanelElements[0].scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    });
    (activePanelElements[0] as HTMLElement).focus({ preventScroll: true });
  });

  useEffect(() => {
    if (error) return setError();
  }, [error]);
  if (loading) return <div>Loading careplan sites list</div>;
  if (!data) return <div></div>;
  const { siteGroupsByCareplan } = data;
  if (!siteGroupsByCareplan) return <div />;

  const allSitesIdx = (siteIdx = '0') => {
    const siteSets: any = [];
    siteGroupsByCareplan.map((group: any) => siteSets.push(group.siteSet));
    return siteSets.reduce((acc: any, val: any) => acc.concat(val), [])[siteIdx];
  };

  const setActvieSiteClass = (idx: string, siteId: string): string => {
    if (
      pathLocation.includes(CAREPLAN_PAGES.PRESCRIPTION) &&
      !pathLocation.includes(CAREPLAN_PAGES.TEMPLATE_SELECTION)
    ) {
      return allSitesIdx(idx).id === siteId ? ' active' : '';
    }
    return '';
  };

  const setActiveGroup = (siteGroupIdx: string, index: string): string => {
    if (
      !pathLocation.includes(CAREPLAN_PAGES.PRESCRIPTION) &&
      !pathLocation.includes(CAREPLAN_PAGES.TEMPLATE_SELECTION)
    ) {
      let idx = siteGroupIdx ? siteGroupIdx : '0';
      if (siteGroupIdx === 'last') {
        const allSiteGroup = siteGroupsByCareplan;
        idx = allSiteGroup ? String(allSiteGroup.length - 1) : '0';
      }
      return index === idx ? ' active' : '';
    }
    return '';
  };

  const setNotificationMessage = (title: number, body: Array<any>): void => {
    const NotificationMessage = () => (
      <Fragment>
        <div
          style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }}
          className="title"
          data-testid="delete-group-message">{`Treatment group ${title} removed`}</div>
        <div style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }}>
          {body.map((site: any, index: number): JSX.Element => {
            return (
              <Fragment key={`${index}-notification-message`}>
                {[
                  getSiteRepresentation(site),
                  extractValueFromSite(site, 'laterality') ? `- ${extractValueFromSite(site, 'laterality')}` : '',
                ].join(' ')}
              </Fragment>
            );
          })}
        </div>
      </Fragment>
    );

    toast.dark(<NotificationMessage />, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide,
      progress: undefined,
    });
  };

  // group the site groups by the careplan template id
  const templates = siteGroupsByCareplan;
  let activeGroup = '';
  let activeSite = '';
  let visibleGroupIndex = 0;

  const getTreatmentTooltip = (index: number, site: any, siteIndex: number, activeSite: any): JSX.Element => {
    const uniqueId = `sp-site-${index}-${siteIndex}`;
    return (
      <>
        <Tooltip
          arrow
          title={
            <div>
              {[
                getSiteRepresentation(site),
                extractValueFromSite(site, 'laterality') ? `- ${extractValueFromSite(site, 'laterality')}` : '',
              ].join(' ')}
            </div>
          }>
          <div
            className={`sidepanel-container ${activeGroup || activeSite}`}
            style={{ borderLeft: activeGroup || activeSite ? `4px solid ${theme.palette.primary.main}` : 'none' }}>
            <div
              className={`sidepanel-site ${activeGroup || activeSite}`}
              key={uniqueId}
              id={uniqueId}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {activeSite && <ArrowRight color="primary" />}
              {/* <span className="sidepanel-green-arrow">►</span> */}
              {[
                getSiteRepresentation(site),
                extractValueFromSite(site, 'laterality') ? `- ${extractValueFromSite(site, 'laterality')}` : '',
              ].join(' ')}
            </div>
          </div>
        </Tooltip>
      </>
    );
  };

  if (!templatesWithValues(templates).length) return <Fragment />;

  const treatmentSiteGroups = (
    <div className="sidepanel-template-contents">
      {!templatesWithValues(templates).length ? (
        <div className={'sidepanel-message'}>
          <span>{NO_TEMPLATES_MESSAGE}</span>
        </div>
      ) : (
        templatesWithValues(templates).map((template: any, index: number): JSX.Element => {
          activeGroup = setActiveGroup(siteGroupIdx, String(index));
          if (hiddenGroups.indexOf(template.id) !== -1) {
            return <Fragment key={`frag-${template.id}`} />;
          }
          visibleGroupIndex = visibleGroupIndex + 1;
          if (template.name) {
            return (
              <Fragment key={`templates-with-values-${index}`}>
                <div
                  data-testid="sidepanel-template-title"
                  className={`sidepanel-template-title ${activeGroup}`}
                  key={`sidepanel-${index}`}>
                  <div
                    data-testid="sidepanel-template-text"
                    className="sidepanel-template-text"
                    key={`sidepanel-text-${index}`}>
                    {activeGroup && <ArrowRight color="primary" />}
                    {/* {activeGroup && <span className="sidepanel-green-arrow">►</span>} */}
                    <span>Treatment group {visibleGroupIndex}</span>
                  </div>
                  {pathLocation.includes(CAREPLAN_PAGES.TEMPLATE_SELECTION) && (
                    <DeleteOutline
                      data-testid="delete-template"
                      color="error"
                      className="close"
                      sx={{ cursor: 'pointer', ':hover': { fillOpacity: 0.75 } }}
                      onClick={(): void => {
                        const newHiddenGroups = hiddenGroups;
                        newHiddenGroups.push(template.id);
                        setHiddenGroups(newHiddenGroups);
                        setNotificationMessage(visibleGroupIndex, template.siteSet);
                        deleteSite({ variables: { id: template.id } });
                      }}
                    />
                  )}
                </div>
                {template.siteSet.map((site: any, siteIndex: number): JSX.Element => {
                  activeSite = setActvieSiteClass(siteIdx, site.id);
                  return <Fragment key={siteIndex}>{getTreatmentTooltip(index, site, siteIndex, activeSite)}</Fragment>;
                })}
              </Fragment>
            );
          } else {
            const manualTemplate = template && template.siteSet[0];
            if (!manualTemplate) return <div />;
            activeSite = setActvieSiteClass(siteIdx, manualTemplate.id);
            return (
              // Manual template
              <Fragment key={template.id}>
                <div
                  data-testid="sidepanel-template-title"
                  className={`sidepanel-template-title ${activeGroup}`}
                  key={`sidepanel-${index}`}>
                  <div
                    data-testid="sidepanel-template-text"
                    className="sidepanel-template-text"
                    key={`sidepanel-text-${index}`}>
                    {/* {activeGroup && <span className="sidepanel-green-arrow">►</span>} */}
                    {activeGroup && <ArrowRight color="primary" />}
                    <span>Treatment group {visibleGroupIndex}</span>
                  </div>
                  {pathLocation.includes(CAREPLAN_PAGES.TEMPLATE_SELECTION) && (
                    <DeleteOutline
                      data-testid="delete-template"
                      color="error"
                      className="close"
                      sx={{ cursor: 'pointer', ':hover': { fillOpacity: 0.75 } }}
                      onClick={(): void => {
                        const newHiddenGroups = hiddenGroups;
                        newHiddenGroups.push(template.id);
                        setHiddenGroups(newHiddenGroups);
                        setNotificationMessage(visibleGroupIndex, template.siteSet);
                        deleteSite({ variables: { id: template.id } });
                      }}
                    />
                  )}
                </div>
                {template.siteSet.map((site: any, siteIndex: number): JSX.Element => {
                  activeSite = setActvieSiteClass(siteIdx, site.id);
                  return <Fragment key={siteIndex}>{getTreatmentTooltip(index, site, siteIndex, activeSite)}</Fragment>;
                })}
              </Fragment>
            );
          }
        })
      )}
    </div>
  );

  return (
    <SidePanelItem title="Treatment groups" topBorderColor={theme.palette.primary.main}>
      {treatmentSiteGroups}
    </SidePanelItem>
  );
};

export const ROPatientCarePathDoseSummaryWidget = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const match: { params: { id: string } } = useRouteMatch();
  const { id: patientId } = match.params;
  const [index, setIndex] = useState(0);
  const { data, loading, error } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  useEffect(() => {
    if (error) {
      return setError('NON_BLOCKING');
    }
  }, [error]);

  useEffect(() => {
    setIndex(0);
  }, [data]);

  if (!data && loading)
    return (
      <Stack sx={{ width: '100%' }} className="px-0">
        <LoadingSpinner loadingText={'Loading dose site summaries'} relativeSpinner={true} />
      </Stack>
    );

  let container = (
    <GeneralError
      primaryText="Unable to load dose site summaries."
      secondaryText="Please contact GenesisCare Innovation support."
    />
  );

  let tracker;

  if (data) {
    const mqDoses = data.doseSiteSummaries.filter((dss: any) => !dss.isAria);
    const ariaDoses = data.doseSiteSummaries.filter((dss: any) => dss.isAria);
    const groupedDose = groupBy(ariaDoses, 'categorySiteGroup');
    const doseSize = mqDoses.length + Object.keys(groupedDose).length;
    const handleSelect = (selectedIndex: number) => {
      setIndex(selectedIndex % doseSize);
    };
    tracker = doseSize ? `${index + 1}/${doseSize}` : '';
    container = (
      <RODoseSiteCarousel
        handleSelect={handleSelect}
        index={index}
        mqDoses={mqDoses}
        ariaDoses={ariaDoses}
        doseSize={doseSize}
      />
    );
  }

  return (
    <SidePanelItem title={`Dose site summary ${tracker}`} topBorderColor={theme.palette.primary.main}>
      {container}
    </SidePanelItem>
  );
};

const ROPatientCarePathSidePanel = (): JSX.Element => {
  return (
    <div className="carepath-site-panel-component">
      <ROSidePanel>
        <ROPatientCarePathDiagnosisWidget />
        <ROPatientCarePathCarePlanSitesWidget />
        <ROPeerReviewSidePanel />
        {CurrentAppConfig.DoseSiteSummary.enabled && <ROPatientCarePathDoseSummaryWidget />}
      </ROSidePanel>
    </div>
  );
};

export default ROPatientCarePathSidePanel;
