// Packages
import React, { Component } from 'react';

import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';

import { ForgotPasswordMutationType } from 'op-px-models';
import { ButtonType } from 'shared-components/enums';
import { FORGOT_PASSWORD_MUTATION_GQL } from 'op-px-graphql';

// Components
import { LoadingSpinner } from 'shared-components/components';
import { FormContent, FormSection, GCButton } from 'shared-components/components/FormComponents';

const DESCRIPTION = 'If you have a valid account, a password reset email has been sent to you';
const RESET_LINK = 'Reset link';
const RESEND_LINK = 'Resend link';

interface Props extends RouteComponentProps<{}, any, Location | any> {
  children?: React.ReactNode;
}

interface State {
  goBack: boolean;
}

class ResetLink extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      goBack: false,
    };
  }

  public render(): JSX.Element {
    // If navigating directly
    const { location, history } = this.props;

    if (!location || !location.state || !location.state.username) {
      return <Redirect from="/" to="/login" />;
    }
    // If back button is pressed
    if (this.state.goBack) {
      history.goBack();
    }

    return (
      <Mutation<{ resetPassword: { resetLink: string; errors: string[] } }> mutation={FORGOT_PASSWORD_MUTATION_GQL}>
        {(requestResetLink, { loading, error }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner />;
          }

          if (error) {
            return <div>Something went wrong, please try again later</div>;
          }

          return this.renderContents(requestResetLink as ForgotPasswordMutationType);
        }}
      </Mutation>
    );
  }

  private renderContents = (requestResetLink: ForgotPasswordMutationType): JSX.Element => {
    const { location } = this.props;

    const resetLink: string | undefined = location.state.resetLink;

    return (
      <div className="auth-container">
        <div className="auth-container-inner">
          <form id="reset-link-form" className="form-wrapper">
            <FormContent>
              <div className="title-container">
                <div
                  className="back-arrow"
                  onClick={(): void => {
                    this.setState({ goBack: true });
                  }}
                />
                <div className="title">{RESET_LINK}</div>
              </div>
              <div className="description" id="modal-text">
                {DESCRIPTION}
              </div>
              {resetLink && (
                <div
                  className="flex-horizontal-center "
                  style={{
                    marginBottom: '1em',
                  }}>
                  <a id="reset-link-debug" className="link link-bold" href={resetLink}>
                    Reset Password Debug Link
                  </a>
                </div>
              )}
              <FormSection>
                <div className="flex-horizontal-center">
                  <GCButton
                    onClick={(e): void => {
                      if (e) {
                        e.preventDefault();
                      }
                      requestResetLink({
                        variables: {
                          username: location.state.username,
                        },
                      });
                    }}
                    name="resend-link"
                    type={ButtonType.GREEN}
                    buttonType="submit"
                    title={RESEND_LINK}
                  />
                </div>
                <div className="flex-horizontal-center">
                  <Link className="link" to="/login" replace>
                    {'Cancel'}
                  </Link>
                </div>
              </FormSection>
            </FormContent>
          </form>
        </div>
      </div>
    );
  };
}

export default withRouter(ResetLink);
