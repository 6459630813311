// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Region } from 'shared-components/enums';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

type SSOPageType = {
  redirectToAzureAd: boolean | undefined;
};

class SSOPage extends Component<SSOPageType> {
  public componentDidMount(): void {
    let pathParts = window.location.href.split('/').slice(3);
    const redirectToAzureAd = Boolean(this.props.redirectToAzureAd);
    if (pathParts[0] === '') {
      pathParts = ['server/auth/redirect'];
    }
    if (
      (REACT_APP_REGION && [String(Region.AU), String(Region.US), String(Region.DEMO)].includes(REACT_APP_REGION)) ||
      redirectToAzureAd
    ) {
      window.location.replace(`/sso/login?next=/${pathParts.join('/')}`);
    }
  }

  public render(): JSX.Element {
    return <div>Redirecting...</div>;
  }
}

export default SSOPage;
