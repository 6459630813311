import React from 'react';
import OuterContainer from '../PatientSummary/OuterContainer';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { Box, Stack, styled, Typography } from '@mui/material';
import { ROPatientCarePathDoseSummaryWidget } from '../Careplan/SidePanel/SidePanel';

import {
  GCButton,
  ROAutocomplete,
  RODatePicker,
  ROTextField,
  SelectOptionType,
} from 'shared-components/components/FormFields';
import { LoadingSpinner } from 'shared-components/components';

import ChipMultiSelect from './ChipMultiSelect';
import { getOptionByValue } from '../Careplan/DiagnosisPages/Utils';
import { useQuery } from '@apollo/client';
import { GET_DOSE_SITE_SUMMARIES } from '../PatientSummary/PatientProfileQueries';
import { useHistory, useRouteMatch } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation } from '@apollo/client';
import { ButtonType, GCButtonSize } from 'shared-components/enums';
import { ON_TREATMENT_REVIEW_MUTATION } from './Queries';
import { ROSidePanel } from 'op-components/index';
import { GCButtonTextSize } from 'shared-components/components/FormFields/GCButton/GCButton';

const StyledNavbarFooter = styled(Stack)`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-top: 2px solid ${(props) => props.theme.palette.primary.main};
  padding: 12px 16px;
  background: white;
  transition: box-shadow 0.15s ease-in-out;
`;
type ValidationStructure = {
  selectedCareplan: boolean;
  treatmentFractionsReviewed: boolean;
  managementDecision: boolean;
  clinicalExamination: boolean;
  responseToTreatment: boolean;
  imagingReview: boolean;
};

const OnTreatmentReview = (): JSX.Element => {
  const match: { params: { id: string } } = useRouteMatch();
  const { id: patientId } = match.params;
  const responseToTreatment = [
    'Expected toxicity for dose prescribed',
    'Symptoms as expected',
    'Doing well',
    'No major concerns',
    'No major complaints',
  ];
  const imagingReview = [
    'IGRT reviewed',
    'PORT films reviewed',
    'No imaging ordered',
    'No changes made to patient set up',
    'Changes were made to patient set up',
  ];
  const managementDecisionOptions = [
    'Continue treatment as planned',
    'Complete treatment as planned',
    'Continue treatment with changes prescribed',
    'Stop treatment',
    'Break in treatment',
    'Recommence treatment',
  ].map((value) => ({
    value,
    label: value,
  }));
  const [reviewDate, setReviewDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const [selectedValuesTreatment, setSelectedValuesTreatment] = React.useState<string[]>([]);
  const [selectedValuesImaging, setSelectedValuesImaging] = React.useState<string[]>([]);
  const [careplanList, setCareplanList] = React.useState<SelectOptionType[]>([]);
  const [fractionsList, setFractionsList] = React.useState<SelectOptionType[]>([]);

  const [selectedCareplan, setSelectedCareplan] = React.useState<SelectOptionType>({ value: '', label: '' });
  const [selectedFractions, setSelectedFractions] = React.useState<SelectOptionType>({ value: '', label: '' });
  const [selectedManagementDecision, setSelectedManagementDecision] = React.useState<SelectOptionType>({
    value: '',
    label: '',
  });
  const [clinicalExamination, setClinicalExaminationo] = React.useState<string>('');
  const [isInValidField, setIsInValidField] = React.useState<ValidationStructure>({
    selectedCareplan: true,
    treatmentFractionsReviewed: true,
    managementDecision: true,
    responseToTreatment: true,
    imagingReview: true,
    clinicalExamination: true,
  });
  const [triggerSubmit, setTriggerSubmit] = React.useState<boolean>(false);
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
  });
  const [onTreatmentReview, { loading: savingOTV }] = useMutation(ON_TREATMENT_REVIEW_MUTATION, {
    onCompleted: (data: any) => {
      if (data?.createTreatmentReview?.treatmentReview) {
        history.push(`/radiation/patient/${patientId}/summary`);
      }
    },
  });
  // update the careplan name when the dose site summary changes
  React.useEffect(() => {
    if (data && data.doseSiteSummaries) {
      const filteredCareplans = data.doseSiteSummaries.map((doseSiteSummary: any) => {
        const doseValue = doseSiteSummary.isAria
          ? doseSiteSummary.plannedDosageQuantity
          : doseSiteSummary.plannedDosageQuantity / 100;
        const doseFractions = doseSiteSummary.plannedDosageRatio;
        const careplanName = `${doseSiteSummary.identifierSite} ${doseSiteSummary.technique} ${doseValue}Gy/${doseFractions}#`;
        return {
          value: careplanName,
          label: careplanName,
        };
      });
      const filteredFractions = data.doseSiteSummaries.map((doseSiteSummary: any) => {
        const doseFractions = doseSiteSummary.plannedDosageRatio / 5;
        // create array of fractions
        return [...Array(doseFractions).keys()].map((val) => ({
          value: val * 5 + 5 < doseFractions ? `${val * 5 + 1}-${doseFractions}` : `${val * 5 + 1}-${val * 5 + 5}`,
          label: val * 5 + 5 < doseFractions ? `${val * 5 + 1}-${doseFractions}` : `${val * 5 + 1}-${val * 5 + 5}`,
        }));
      });

      setCareplanList(filteredCareplans);
      setFractionsList(filteredFractions.flat());
    }
  }, [data]);
  const handleChange = (values: string[], type: 'treatment' | 'imaging') => {
    if (type === 'treatment') {
      setSelectedValuesTreatment(values);
    } else {
      setSelectedValuesImaging(values);
    }
  };

  const submitForm = () => {
    onTreatmentReview({
      variables: {
        patientId,
        reviewDate: reviewDate?.format('YYYY-MM-DD'),
        careplan: selectedCareplan.value,
        treatmentFractionsReviewed: selectedFractions.value,
        responseToTreatment: selectedValuesTreatment.join(', '),
        imageReview: selectedValuesImaging.join(', '),
        managementDecision: selectedManagementDecision.value,
        clinicalExamination,
        dss: JSON.stringify(data.doseSiteSummaries),
      },
    });
  };
  const triggerValidation = () => {
    return new Promise((resolve, reject) => {
      isValid('selectedCareplan', selectedCareplan.value);
      isValid('treatmentFractionsReviewed', selectedFractions.value);
      isValid('managementDecision', selectedManagementDecision.value);
      isValid('clinicalExamination', clinicalExamination);
      isValid('responseToTreatment', selectedValuesTreatment.join(', '));
      isValid('imagingReview', selectedValuesImaging.join(', '));

      Object.entries(isInValidField).find(([key, value]) => value === true) ? reject() : resolve(true);
    });
  };
  const isValid = (fieldName: string, value: any) => {
    if (String(value) === '') {
      setIsInValidField((prev) => ({ ...prev, [fieldName]: true }));
    } else {
      setIsInValidField((prev) => ({ ...prev, [fieldName]: false }));
    }
  };
  const handleChangeTreatment = (values: string[]) => {
    handleChange(values, 'treatment');
    isValid('responseToTreatment', values);
  };
  const handleChangeImaging = (values: string[]) => {
    handleChange(values, 'imaging');
    isValid('imagingReview', values);
  };
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Stack direction="row" sx={{ width: '100%', margin: '-8px 0px' }}>
          <Stack sx={{ width: '100%' }}>
            <Stack sx={{ paddingLeft: '24px', width: '100%', flex: 1, overflowY: 'auto' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                On Treatment Review Note
              </Typography>
              <Typography variant="body1">*= required field</Typography>
              <Stack sx={{ paddingTop: '24px', width: '100%' }}>
                <RODatePicker
                  id="reviewDate"
                  fieldlabel="Review Date"
                  value={reviewDate ? dayjs(reviewDate) : null}
                  onChange={(date: Dayjs | null): void => {
                    setReviewDate(date);
                  }}
                />
                <ROAutocomplete
                  id="selectedCareplan"
                  fieldlabel="Select Careplan"
                  required
                  options={careplanList}
                  inputProps={{ error: triggerSubmit && isInValidField.selectedCareplan }}
                  value={getOptionByValue(careplanList, selectedCareplan ? selectedCareplan.value : '')}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setSelectedCareplan({ value, label: value });
                    isValid('selectedCareplan', value);
                  }}
                />
                <ROAutocomplete
                  id="treatmentFractionsReviewed"
                  fieldlabel="Treatment fractions reviewed"
                  required
                  inputProps={{ error: triggerSubmit && isInValidField.treatmentFractionsReviewed }}
                  options={fractionsList}
                  value={getOptionByValue(fractionsList, selectedFractions ? selectedFractions.value : '')}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setSelectedFractions({ value, label: value });
                    isValid('treatmentFractionsReviewed', value);
                  }}
                />

                <ChipMultiSelect
                  fieldLabel="Response to treatment"
                  selectedValues={selectedValuesTreatment}
                  handleChange={handleChangeTreatment}
                  options={responseToTreatment}
                  placeholder="Please select"
                  error={triggerSubmit && isInValidField.responseToTreatment}
                />
                <ChipMultiSelect
                  fieldLabel="Imaging review"
                  selectedValues={selectedValuesImaging}
                  handleChange={handleChangeImaging}
                  options={imagingReview}
                  placeholder="Please select"
                  error={triggerSubmit && isInValidField.imagingReview}
                />
                <ROAutocomplete
                  id="managementDecision"
                  fieldlabel="Management decision"
                  required
                  inputProps={{ error: triggerSubmit && isInValidField.managementDecision }}
                  options={managementDecisionOptions}
                  value={getOptionByValue(
                    managementDecisionOptions,
                    selectedManagementDecision ? selectedManagementDecision.value : '',
                  )}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setSelectedManagementDecision({ value, label: value });
                    isValid('managementDecision', value);
                  }}
                />
                <ROTextField
                  id="clinicalExamination"
                  fieldlabel="Clinical examination"
                  required
                  error={triggerSubmit && isInValidField.clinicalExamination}
                  minRows={4}
                  value={clinicalExamination || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setClinicalExaminationo(event.target.value);
                    isValid('clinicalExamination', event.target.value);
                  }}
                  multiline
                  maxRows={4}
                  placeholder="Record details of any diagnostic tests, vital signs, coordinated care & physical exam"
                />
              </Stack>
            </Stack>
            <StyledNavbarFooter spacing={2} justifyContent="flex-end">
              <Box className="button-list" style={{ marginRight: '8px' }}>
                <GCButton
                  id={'footer-button-back'}
                  title={'Cancel'}
                  loading={false}
                  onClick={() => {
                    history.push(`/radiation/patient/${patientId}/summary`);
                  }}
                  type={ButtonType.DISABLED}
                  buttonSize={GCButtonSize.SMALL}
                  textSize={GCButtonTextSize.SMALLER}
                />
              </Box>
              <Box className="button-list float-right" style={{ marginTop: '0 ' }}>
                <GCButton
                  id={'footer-button-continue'}
                  title={'Generate PDF'}
                  loading={false}
                  onClick={() => {
                    setTriggerSubmit(true);
                    triggerValidation().then(() => {
                      submitForm();
                    });
                  }}
                  disabled={savingOTV}
                  type={ButtonType.GREEN}
                  buttonSize={GCButtonSize.SMALL}
                  textSize={GCButtonTextSize.SMALLER}
                />
              </Box>
            </StyledNavbarFooter>
          </Stack>
          <ROSidePanel>
            <ROPatientCarePathDoseSummaryWidget />
          </ROSidePanel>
        </Stack>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OnTreatmentReview;
