// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import VerificationInput from 'react-verification-input';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import axios from 'axios';
import throttle from 'lodash.throttle';

import { styled } from '@mui/system';

import { withRouter, RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import 'url-search-params-polyfill';

import './MultifactorAuth.scss';

import { ButtonType } from 'shared-components/enums';
import { Logger } from 'shared-components/utils';

import { Form, FormContent, FormSection, GCButton, SectionField } from 'shared-components/components/FormComponents';
import { ErrorInfo, Checkbox } from 'shared-components/components/FormFields';
import MultifactorAuthFormViewModel from './MultifactorAuthFormViewModel';
import { NEXT_SEARCH_PARAM } from 'shared-components/models/ApolloHandlerModel';
import Cookies from 'js-cookie';
import { Box, Stack, Tooltip } from '@mui/material';

const logger = new Logger('MultifactorAuth');

interface MFARedirection {
  pathname: string;
  state?: {
    next?: string | null;
    resetPassword?: boolean;
    resetToken?: string;
  };
}

interface VerifyResults {
  error?: string;
  details?: string;
  forcedReset?: boolean;
  username?: string;
}

interface SendTokenResults {
  error?: string;
  details?: string;
  token?: string;
  user?: string;
}

interface StyledProps {
  $cursor: string;
}

const ResendPasscodeLink = styled('span')<StyledProps>`
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration-line: underline;
  cursor: ${({ $cursor }: StyledProps): string => $cursor};
`;

// CONSTANTS
export const DEFAULT_DASHBOARD = '/px/appointments';
const LOGIN_ENDPOINT = '/login';
const BACKEND_MFA_ENDPOINT = '/server/patient/auth/mfa';
const FORM_TITLE = 'Verification Passcode';
const FORM_DESCRIPTION = 'A text message with your passcode has been sent to: **** *** ';
const FORM_INSTRUCTION = 'Enter 6 digit code';
const FORM_VERIFY_BUTTON_TEXT = 'Verify';
const FORM_RESEND_LINK_TEXT = 'Resend passcode';
const MFA_ERROR = 'Please enter a valid code or resend a new passcode';
const MFA_MAX_ATTEMPTS_ERROR =
  'Maximum attempts reached. Please wait 15 minutes to try again, or contact support if experiencing ongoing issues.';
const INVITE_SEARCH_PARAM = 'invite';
const TIME_BETWEEN_MFA_CODES = '15';

// GRAPH QL
export const SEND_REQUEST = gql`
  mutation Send {
    send {
      token
      user {
        lastThreeMobile
      }
      errors
    }
  }
`;

interface Props extends RouteComponentProps<{}, any, Location | any>, WithApolloClient<{}> {}

interface State {
  goBack: boolean;
  lastThreeMobile: string | undefined;
  debugToken: string | undefined;
  verifyLoading: boolean;
  verifyData: VerifyResults;
  errorMessage: string | undefined;
  sendTokenLoading: boolean;
  sendTokenData: SendTokenResults;
  rememberDevice: boolean;
  enableResendToken: boolean;
  minutesUntilEnabled: number;
  intervalId: NodeJS.Timeout | number;
  pin: number | undefined;
}

class MultifactorAuth extends Component<Props, State> {
  public mfaFormViewModel: MultifactorAuthFormViewModel;
  private inviteReferral = false;

  // VARIABLES
  private nextAddress?: string;

  public constructor(props: Props) {
    super(props);
    this.mfaFormViewModel = new MultifactorAuthFormViewModel();

    let lastThreeMobile = undefined;
    let debugToken = undefined;

    const propsLocation = props.location;

    if (propsLocation && propsLocation.state) {
      // Set the next address variable from the passed in props state
      this.nextAddress = propsLocation.state[NEXT_SEARCH_PARAM];

      // Set the local state variable for last three mobile
      if (propsLocation.state.user) {
        if (propsLocation.state.user.lastThreeMobile) {
          lastThreeMobile = propsLocation.state.user.lastThreeMobile;
        }
      }
      // Set the debugToken
      if (propsLocation.state.mfaToken) {
        debugToken = propsLocation.state.mfaToken;
      }
    }

    // Throttle 'Resend token' function so it can't be continuously called over and over
    this.getToken = throttle(this.getToken, 60000, { leading: true });

    // On first load get values from session storage
    const enableResendToken = sessionStorage.getItem('enableResendToken') === 'true' ? true : false;
    const minutesUntilEnabled = Number(
      sessionStorage.getItem('minutesUntilEnabled') !== null
        ? sessionStorage.getItem('minutesUntilEnabled')
        : TIME_BETWEEN_MFA_CODES,
    );

    // Set the initital state
    this.state = {
      goBack: false,
      lastThreeMobile,
      debugToken,
      verifyLoading: false,
      verifyData: {},
      errorMessage: undefined,
      sendTokenLoading: false,
      sendTokenData: {},
      rememberDevice: false,
      enableResendToken,
      minutesUntilEnabled,
      intervalId: 0,
      pin: undefined,
    };
  }

  public componentDidUpdate(prevProps: any, prevState: any): void {
    // Update session storage any time state changes
    if (this.state.enableResendToken !== prevState.enableResendToken) {
      if (this.state.enableResendToken) {
        // Clear countdown if timer has finished
        clearInterval(this.state.intervalId);
        this.setState({ minutesUntilEnabled: Number(TIME_BETWEEN_MFA_CODES) });
      }
      sessionStorage.setItem('enableResendToken', String(this.state.enableResendToken));
    }
    if (this.state.minutesUntilEnabled !== prevState.minutesUntilEnabled) {
      sessionStorage.setItem('minutesUntilEnabled', String(this.state.minutesUntilEnabled));
    }
  }

  public componentDidMount(): void {
    const {
      location: { search },
    } = this.props;

    const urlSearchParams = new URLSearchParams(search);
    const inviteReferral = urlSearchParams.get(INVITE_SEARCH_PARAM);
    const token = urlSearchParams.get('token');

    // This mutation will fire if we're navigating to mfa after being authenticated.
    // This occurs when an OP to PX invite URL is clicked and authentication is done on the back end.
    // Or when we have opening the page from a reminder email
    // Also since the MFA request would not have been called as Login does it otherwise.
    if (inviteReferral || token) {
      this.getToken();
    }

    // If user has refreshed the page re-start timer from where they were previously
    if (!this.state.enableResendToken) {
      this.countdownToResendToken();
    }
  }
  private getCSRFCookie(): string {
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      return csrfToken;
    }
    return 'invalid_token';
  }
  public verifyToken(smsToken: string, rememberDevice: boolean): void {
    this.setState({ verifyLoading: true, errorMessage: undefined });
    const {
      location: { search },
    } = this.props;
    const urlSearchParams = new URLSearchParams(search);
    const userToken = urlSearchParams.get('token');

    // User token is only for reminder email to reroute to sign up page
    if (userToken) {
      logger.debug('verifyToken', 'Validating a signup token with MFA');
      const postData = {
        token: userToken,
        smstoken: smsToken,
      };

      axios
        .post('/server/patient/validate_email', postData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': this.getCSRFCookie(),
          },
        })
        .then((response): void => {
          // Redirection
          if (response.data.success) {
            this.props.history.replace('/px/signup');
          }
        })
        .catch((_error): void => {
          this.setState({ verifyLoading: false });
        });
    } else {
      logger.debug('verifyToken', 'Validate MFA token to login');
      const postData = {
        smstoken: smsToken,
        rememberDevice,
      };

      axios
        .post(BACKEND_MFA_ENDPOINT, postData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': this.getCSRFCookie(),
          },
        })
        .then((response): void => {
          // Redirection
          this.goToNextPage(response.data.forcedReset);
          this.setState({ verifyLoading: false, verifyData: response.data });
        })
        .catch((error): void => {
          if (error.response.status === 403) {
            this.setState({ errorMessage: MFA_MAX_ATTEMPTS_ERROR });
          } else {
            this.setState({ errorMessage: MFA_ERROR });
          }
          this.setState({ verifyLoading: false, verifyData: error.response.data });
        });
    }
  }

  public countdownToResendToken(): void {
    this.setState({ enableResendToken: false });

    // Re-enable resend token after 15 minutes
    setTimeout(() => this.setState({ enableResendToken: true }), this.state.minutesUntilEnabled * 60000);

    // Set interval for tooltip countdown
    const id = setInterval(() => {
      this.setState({ minutesUntilEnabled: this.state.minutesUntilEnabled - 1 });
    }, 60000);

    this.setState({ intervalId: id });
  }

  public handleResendToken(): void {
    if (this.state.enableResendToken) {
      this.countdownToResendToken();
      this.getToken();
    }
  }

  public getToken(): void {
    this.setState({ sendTokenLoading: true });
    const {
      location: { search },
    } = this.props;

    const urlSearchParams = new URLSearchParams(search);
    const token = urlSearchParams.get('token');

    const sendTokenData: any = {};

    if (token) {
      sendTokenData['usertoken'] = token;
    }

    axios
      .post('/server/patient/auth/send_token', sendTokenData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': this.getCSRFCookie(),
        },
      })
      .then((response): void => {
        this.setState({ sendTokenLoading: false, sendTokenData: response.data });
      })
      .catch((error): void => {
        logger.info('getToken', 'error: ', error);
        this.setState({ sendTokenLoading: false });
        this.props.history.replace(LOGIN_ENDPOINT);
      });
  }

  public goToNextPage = (forcedReset?: boolean): void => {
    const {
      location: { search },
    } = this.props;

    const urlSearchParams = new URLSearchParams(search);
    const redirectAddress = urlSearchParams.get('redirectto');

    if (redirectAddress) {
      this.props.history.push(redirectAddress);
      return;
    }

    const resetPassword: boolean | undefined = this.props.location.state.resetPassword;
    const resetToken: string | undefined = this.props.location.state.resetToken;

    if (forcedReset || resetPassword) {
      const redirectionParams: any = {
        pathname: '/px/changePassword',
        state: {
          next: this.nextAddress,
          resetPassword: resetPassword,
          resetToken: resetToken,
        },
      };

      this.props.history.push(redirectionParams);
      return;
    }

    if (this.nextAddress) {
      // Redirect the window location to the next address
      if (this.nextAddress.indexOf('/server/') !== -1) {
        window.location.replace(this.nextAddress);
      }

      this.props.history.push(this.nextAddress);
      return;
    }

    // Redirect the window location to appointments
    this.props.history.push(DEFAULT_DASHBOARD);
    return;
  };

  public render(): JSX.Element {
    const {
      location: { search, state },
    } = this.props;

    // Need to validate if we're coming to MFA via invite link.
    // Since we'd already be authenticated, no values would be set in `props.location.state`.
    // Acts as a bypass mechanism.
    this.inviteReferral = search.includes('invite=true');

    // Go back has been pressed so redirect
    if (this.state.goBack) {
      return <Redirect from="/px/mfa" to={LOGIN_ENDPOINT} />;
    }

    const urlSearchParams = new URLSearchParams(search);
    const token = urlSearchParams.get('token');

    // Only render when coming from login or a token in the url (From reminder email)
    if ((this.props.location && this.props.location.state) || this.inviteReferral || token) {
      // Prop assignment is not ideal, however, this statement is for invite referral and navigation to MFA not from Login
      // Since we access items that may or may not be inside state, we need a blank object to prevent crashing
      this.props.location.state = state ? state : {};
      return this.sendTokenRender();
    }

    // There is no location, so redirect back to login
    let pathName = '/';

    if (this.nextAddress) {
      pathName = `?next=${this.nextAddress}`;
    }

    return <Redirect to={pathName} />;
  }

  /**
   * Function that will call the mutation for grabbing the token from the graph ql server and then rendering the output.
   * @returns {JSX.Element} The rendered elements on screen.
   */
  private sendTokenRender = (): JSX.Element => {
    if (this.state.sendTokenLoading) return this.contentsRender(false, false, false);

    // Occurs only if loaded from a user login token and token was invalid
    if (!this.state.sendTokenData) return <Redirect to={LOGIN_ENDPOINT} />;

    // Errors state for sending a token
    if (this.state.sendTokenData.error) return <div>Error with retrieving token</div>;

    // Verify token loading state
    if (this.state.verifyLoading) return this.contentsRender(false, false, this.state.verifyLoading);

    // If debug token from login page call then display it
    let token: string | undefined = this.state.debugToken;

    // If debug token from resend button display it
    if (this.state.sendTokenData.token) {
      // Updating the state.user if it's not there already.
      token = this.state.sendTokenData.token;
    }

    // Errors for retrieving a token
    if (this.state.verifyData && (this.state.verifyData.error || this.state.errorMessage))
      return this.contentsRender(true, false, this.state.verifyLoading, token);

    // Check for the user and user name, and if exists then the user is verified
    let verified = false;
    let forcedReset = false;

    if (this.state.verifyData && this.state.verifyData.forcedReset) {
      verified = this.state.verifyData.username ? true : false;
      forcedReset = this.state.verifyData.forcedReset;
    }
    return this.contentsRender(false, verified, this.state.verifyLoading, token, forcedReset);
  };

  /**
   * Function that will render the contents
   * @param {boolean} verificationError There is an error detected from the verification.
   * @param {boolean} redirect There is a need to redirect the pages.
   * @param {boolean} verifyLoading The verification of the token is running.
   * @param {string} token Optional, if the token exists and the application is running in development mode, the token will be displayed on screen for debug purposes. This may need to be changed.
   * @param {any} verifyMutation Optional, the function that will call the mutation to verify the token.
   * @param {boolean} forcedReset Optional, whether the MFA is due to a forced reset.
   */
  private contentsRender = (
    verificationError: boolean,
    redirect: boolean,
    verifyLoading: boolean,
    token?: string,
    forcedReset?: boolean,
  ): JSX.Element => {
    const { lastThreeMobile, rememberDevice, enableResendToken, minutesUntilEnabled, pin } = this.state;
    const { mfaFormModel } = this.mfaFormViewModel;
    // Redirect if the verification has passed
    if (redirect) {
      return this.goToNext(forcedReset);
    }

    const {
      location: { search },
    } = this.props;

    const urlSearchParams = new URLSearchParams(search);
    const hasEmailSignUpToken = urlSearchParams.get('token');
    const styledProps = {
      $cursor: enableResendToken ? 'pointer' : 'default',
    };

    return (
      <div className="auth-container" key={mfaFormModel?.formData?.pin}>
        <div className="auth-container-inner">
          <Form id="patient-mfa-form" formData={mfaFormModel}>
            <FormContent>
              <div className="title-container">
                <div
                  className={classNames('back-arrow', {
                    hidden: this.props.location && this.props.location.state && this.props.location.state.resetPassword,
                  })}
                  onClick={(): void => {
                    this.setState({ goBack: true });
                  }}
                />
                <div className="title">{FORM_TITLE}</div>
              </div>
              <FormSection>
                <div className="description">{lastThreeMobile && FORM_DESCRIPTION + lastThreeMobile}</div>
                <SectionField htmlFor="pin" title={FORM_INSTRUCTION} invalidInput={verificationError}>
                  <VerificationInput
                    key={mfaFormModel?.formData?.pin}
                    containerProps={{ className: 'pin-input' }}
                    validChars="0-9"
                    length={6}
                    autoFocus
                    value={mfaFormModel?.formData?.pin}
                    onChange={(value: any): void => {
                      this.setState({ pin: value });
                      this.mfaFormViewModel.validateField('pin', value);
                    }}
                  />
                </SectionField>
                {verificationError && this.state.errorMessage !== undefined && (
                  <ErrorInfo errors={[this.state.errorMessage]} />
                )}
                {!hasEmailSignUpToken && (
                  <div id="remember-checkbox">
                    <Checkbox
                      inputLabel={"Don't ask me again" || ''}
                      inputName="dont-ask-again"
                      isChecked={rememberDevice}
                      onChange={(e) => {
                        this.setState({ rememberDevice: e });
                      }}
                    />
                  </div>
                )}

                <div className="flex-horizontal-center">
                  <GCButton
                    onClick={(): void => {
                      this.verifyToken(mfaFormModel.formData.pin, this.state.rememberDevice);
                    }}
                    type={ButtonType.GREEN}
                    title={FORM_VERIFY_BUTTON_TEXT}
                    name="mfa-verify"
                    loading={verifyLoading}
                  />
                </div>
                <Stack justifyContent="center">
                  {this.props.location.state.resetPassword && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Link className="link" to={LOGIN_ENDPOINT} replace>
                        Cancel
                      </Link>
                    </Box>
                  )}
                  <Box marginTop={this.props.location.state.resetPassword ? '15px' : '0px'} textAlign={'center'}>
                    <Tooltip
                      arrow
                      data-test-id="resend-token-link-tooltip"
                      title={
                        !enableResendToken &&
                        `Please try again in ${minutesUntilEnabled} minute${minutesUntilEnabled > 1 && 's'}`
                      }>
                      <ResendPasscodeLink
                        id="resend-token-link"
                        onClick={(): void => {
                          this.handleResendToken();
                        }}
                        style={{ textAlign: 'center' }}
                        {...styledProps}>
                        {FORM_RESEND_LINK_TEXT}
                      </ResendPasscodeLink>
                    </Tooltip>
                  </Box>
                </Stack>
                {this.renderToken(token)}
              </FormSection>
            </FormContent>
          </Form>
        </div>
      </div>
    );
  };

  /**
   * Function that will navigate to the next location given that the next address has been set, otherwise will navigate to the dashboard location.
   * @param {boolean | undefined} forcedReset If there is a forced reset to navigate to the change password
   * @returns The redirect JSX element that will be used to re-route the pages.
   */
  private goToNext = (forcedReset?: boolean): JSX.Element => {
    const resetPassword: boolean | undefined = this.props.location.state.resetPassword;
    const resetToken: string | undefined = this.props.location.state.resetToken;

    if (forcedReset || resetPassword) {
      const redirectionParams: MFARedirection = {
        pathname: '/px/changePassword',
        state: {
          next: this.nextAddress,
          resetPassword: resetPassword,
          resetToken: resetToken,
        },
      };

      return <Redirect from="/" to={redirectionParams} />;
    }

    if (this.nextAddress) {
      // Redirect the window location to the next address
      if (this.nextAddress.indexOf('/server/') !== -1) {
        window.location.replace(this.nextAddress);
        return <div />;
      }

      return <Redirect from="/" to={this.nextAddress} />;
    }

    // Redirect the window location to appointments
    return <Redirect from="/" to={DEFAULT_DASHBOARD} />;
  };

  /**
   * Function that will render the token onto the screen for development purposes. It will render the passed in token, or the token that has been passed into the props.
   * @returns {JSX.Element | null} The token will only be rendered if running as a development environment. Otherwise, will not display.
   */
  private renderToken = (token?: string): JSX.Element | null => {
    if (token) {
      return (
        <div id="mfa-token" style={{ paddingTop: '10px' }}>
          {token}
        </div>
      );
    }
    return null;
  };
}

// @ts-ignore
const apolloComponent = withApollo(MultifactorAuth);
// @ts-ignore
export default withRouter(apolloComponent);
