import { BottomSection, HeaderSection } from 'op-pages/PX/Components';
import { ReactNode, memo } from 'react';
import { Stack } from '@mui/material';

interface AuthLayoutIProps {
  children: ReactNode;
  titleOverride?: string;
  clickLogoFn?: () => void;
  showHeader?: boolean;
  showNavigation?: boolean;
  showBottomNav?: boolean;
}

const AuthLayout = (props: AuthLayoutIProps): JSX.Element => {
  const {
    children,
    titleOverride,
    clickLogoFn,
    showHeader = true,
    showNavigation = true,
    showBottomNav = true,
  } = props;
  const componentId = 'auth-layout';

  return (
    <div style={{ height: '100vh' }}>
      <Stack id={componentId} padding={0} width={1} height={1}>
        {showHeader && (
          <HeaderSection
            titleOverride={titleOverride}
            clickLogoFn={clickLogoFn}
            showNavigation={showNavigation}
            displayProfile={showNavigation}
          />
        )}
        {children}
        {showBottomNav && showNavigation && <BottomSection />}
      </Stack>
    </div>
  );
};

export default AuthLayout;
