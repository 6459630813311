import { ButtonMode, ButtonType } from 'shared-components/enums';
import { CAREPLAN_STATUS, redirectCareplanPath } from 'op-pages/RO/Careplan/Common';
import { Documents, Edit, ErrorCircleFill } from 'shared-components/images';
import { GET_PATIENT_CAREPLAN_LIST } from 'op-pages/RO/PatientSummary/PatientProfileQueries';
import { Link, generatePath } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { StyledQuickAccessRibbon, generateCareplanName } from './common';
import { useErrorModalContext } from 'op-contexts';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { CareplanStatusBubble } from './CareplanStatusBubble';
import DeleteCareplan from './DeleteCareplan';
import { LabsIcon } from 'shared-components/images/ro_portal';
import { OutcomesIcon } from 'shared-components/images/ro_portal';
import { hasSubmitted, changesFromLastEdited } from 'op-pages/RO/Careplan/Banner/Banner';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { styled } from '@mui/system';
import { useQuery } from '@apollo/client';
import { Delete } from 'shared-components/components';
import { AppConfig, CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Careplan } from 'op-pages/RO/Careplan/Interface';
import PatientEMR from 'op-components/SideNav/PatientEMR';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { isDemo } from 'op-utils';
import { PollOutlined as PollOutlinedIcon, ControlPoint, Assignment, Ballot, Poll } from '@mui/icons-material';

interface ROPatientDashboardNavType {
  id: string;
}

interface PatientNavOptionType {
  icon: any;
  label: string;
  linkTo: string;
  id: string;
  disabled: boolean;
  isExternal: boolean;
  isVisible: boolean;
}

const menuOptions: PatientNavOptionType[] = [
  {
    icon: isDemo ? <PollOutlinedIcon color="primary" /> : <PollOutlinedIcon />,
    label: 'Patient summary',
    linkTo: '/radiation/patient/:id/summary',
    id: 'summary',
    disabled: false,
    isExternal: false,
    isVisible: true,
  },
  {
    icon: <LinearScaleIcon />,
    label: 'Pathway',
    linkTo: '/radiation/patient/:id/pathway',
    id: 'pathway',
    disabled: false,
    isExternal: false,
    isVisible: Boolean(isDemo),
  },
  {
    icon: <Documents />,
    label: 'Documents',
    linkTo: '/radiation/patient/:id/documents/view',
    id: 'documents',
    disabled: false,
    isExternal: false,
    isVisible: true,
  },
  {
    icon: <Edit />,
    label: 'Notes',
    linkTo: '/radiation/patient/:id/notes',
    id: 'notes',
    disabled: false,
    isExternal: false,
    isVisible: true,
  },
  {
    icon: <LabsIcon />,
    label: 'Labs',
    linkTo: '/radiation/patient/:id/labs',
    id: 'labs',
    disabled: false,
    isExternal: false,
    isVisible: CurrentAppConfig.PatientSummary.labsEnabled,
  },
  {
    icon: <Assignment />,
    label: 'On Treatment Review',
    linkTo: '/radiation/patient/:id/on-treatment-review',
    id: 'on-treatment-review',
    disabled: false,
    isExternal: false,
    isVisible: CurrentAppConfig.OTRPage.isVisible,
  },

  {
    icon: isDemo ? <OutcomesIcon /> : <Ballot />,
    label: 'Outcomes',
    linkTo: '/radiation/patient/:id/outcomes',
    id: 'outcomes',
    disabled: false,
    isExternal: false,
    isVisible: CurrentAppConfig.OutcomesPage.isVisible,
  },
];

const StyledCareplanName = styled('p')`
  font-style: normal;
  font-weight: normal;
  font-size: ${getRemSize(14)};
  line-height: 1.25em;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.primary};
  min-width: 100%;
`;

const StyledDiagnosisCode = styled('span')`
  font-style: normal;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 1.25em;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledDiagnosisTitleDiv = styled('div')`
  margin: 0;
`;

const StyledDiagnosisCodeDescription = styled('span')`
  font-style: normal;
  font-weight: normal;
  font-size: ${getRemSize(14)};
  line-height: 1.25em;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledCareplansHeader = styled('h3')`
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1.7em 0 0 12px;
  align-self: flex-start;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledPatientNavContainer = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 11px;

  &:hover {
    background: ${(props) => props.theme.palette.secondary.main};
    border-radius: 4px;

    a {
      text-decoration: none;
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const CareplanDetailWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledScrollableRow = styled(Stack)`
  overflow-y: auto;
  max-height: 55vh;
  min-width: 100%;
`;

const StyledCareplanListItem = styled('div')`
  padding: 0 4px;
  cursor: pointer;
`;

const StyledNavLinksContainer = styled(Stack)`
  background-color: white;
  padding-top: 16px;
  padding-left: 7px;
  width: 100%;
`;

const UnsubmittedTooltip = styled('div')`
  color: ${(props) => props.theme.palette.warning.dark};
  font-weight: bold;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`;

const PatientNavLinks = (props: any): JSX.Element | null => {
  const { linkTo, icon, label, id, disabled } = props.option;
  const location = useLocation();
  const match = useRouteMatch<ROPatientDashboardNavType>();
  const splitPath = location.pathname.split('/');
  const isActive = splitPath.includes(id.toLowerCase());
  const { id: patientId } = match.params;
  const url = generatePath(linkTo, { id: patientId });
  const active = isActive ? 'active' : '';
  return <NavLinks url={url} active={active} label={label} disabled={disabled} icon={icon} id={id} />;
};

const ExternalNavLinks = (props: any): JSX.Element | null => {
  const { linkTo, icon, label, disabled, id } = props.option;
  return (
    <NavLinks
      id={id}
      url={{ pathname: linkTo }}
      icon={icon}
      label={label}
      disabled={disabled}
      active=""
      additionalProps={{ target: '_blank' }}
    />
  );
};

const NavLinks = (props: any): JSX.Element | null => {
  const { url, active, label, disabled, icon, additionalProps, id } = props;
  const theme = useTheme();

  const dataTestId = `ro-nav-link-${id}`;
  const linkClass = `ro-side-patient-nav-item-row ${active}`;

  return disabled ? (
    <div
      data-test-id={dataTestId}
      className={linkClass}
      style={{
        backgroundColor: active ? theme.palette.primary.dark : 'default',
      }}>
      <div className="ro-side-patient-nav-item">
        {icon}
        <span className="ro-side-patient-nav-item-title">
          <Typography
            variant="h6"
            sx={{ color: active ? theme.palette.primary.contrastText : theme.palette.text.primary }}>
            {label}
          </Typography>
        </span>
      </div>
    </div>
  ) : (
    <div
      data-test-id={dataTestId}
      className={linkClass}
      style={{
        backgroundColor: active ? theme.palette.primary.dark : 'default',
      }}>
      <Link to={url} className="ro-side-patient-nav-item" {...additionalProps}>
        {icon}
        <span className="ro-side-patient-nav-item-title">
          <Typography
            variant="h6"
            sx={{ color: active ? theme.palette.primary.contrastText : theme.palette.text.primary }}>
            {label}
          </Typography>
        </span>
      </Link>
    </div>
  );
};

interface CareplanNavLinkProps {
  careplan: Careplan;
  patientId: string;
  key: string;
  index: number;
}

const CareplanNavLinks = ({ careplan, patientId, index }: CareplanNavLinkProps): JSX.Element => {
  const [careplanHovered, setCareplanHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { id, careplanStatus, diagnosis } = careplan;
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const { data: pracData } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
    skip: !patientId,
  });

  const practitionerTimezone = useMemo(
    () => pracData?.practitioner?.timezone || CurrentAppConfig.DefaultTimezone,
    [pracData?.practitioner?.timezone],
  );
  const careplanPath = redirectCareplanPath(careplan, patientId);
  const careplanStatusText = careplanStatus.replace('_', '-').toLowerCase();
  const hasUnsubmittedChanges = hasSubmitted(careplan, practitionerTimezone) && changesFromLastEdited(careplan);

  const careplanName = generateCareplanName(careplan.sitegroupSet);
  const handleClickDelete = (event: any) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setIsPopUpOpen(!isPopUpOpen);
  };

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    setCareplanHovered(false);
  };
  const target = `template-${id}`;
  const voidTimeRestriction = CurrentAppConfig.CareplanVoidTimeRestriction;

  const submittedAtDate = moment(careplan?.submittedAt);
  const timeDifference = moment.duration(moment().diff(submittedAtDate)).asHours();

  const careplanCanBeVoidedOrDeleted = careplan?.submittedAt ? timeDifference < voidTimeRestriction : true;
  return (
    <>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              color: 'black',
              lineHeight: '1.4rem',
              bgcolor: 'white',
              minWidth: '300px',
              fontSize: '0.9rem',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            },
          },
        }}
        enterDelay={700}
        enterNextDelay={700}
        title={
          careplanStatus &&
          careplanStatus !== CAREPLAN_STATUS.voided && (
            <>
              <span>
                Status:{' '}
                <strong style={{ textTransform: 'capitalize' }}>
                  {careplanStatusText === 'cpot' ? 'CPOT' : careplanStatusText}
                </strong>
              </span>
              <br />
              {careplanName && (
                <span>
                  Careplan: <strong>{careplanName}</strong>
                </span>
              )}
              {hasUnsubmittedChanges && (
                <UnsubmittedTooltip>
                  <ErrorCircleFill /> Unsubmitted changes
                </UnsubmittedTooltip>
              )}
            </>
          )
        }>
        <StyledCareplanListItem
          onMouseEnter={() => setCareplanHovered(true)}
          onMouseLeave={() => setCareplanHovered(false)}
          key={id}
          data-test-id={`careplan-nav-link-${index}`}
          id={`template-${id}`}>
          <StyledPatientNavContainer style={{ display: 'flex', flexDirection: 'row' }}>
            <CareplanDetailWrapper>
              <CareplanStatusBubble status={careplanStatus} hasUnsubmittedChanges={hasUnsubmittedChanges} />
              <Link to={careplanPath} data-testid={`careplan-id-${id}`} style={{ textDecoration: 'none' }}>
                {careplanName ? (
                  <StyledCareplanName> {careplanName} </StyledCareplanName>
                ) : (
                  <StyledDiagnosisTitleDiv>
                    <StyledDiagnosisCode> {diagnosis.diagnosisCode?.diagnosisCode} </StyledDiagnosisCode>
                    <StyledDiagnosisCodeDescription>
                      {diagnosis.diagnosisCode?.diagnosisDescription || 'Draft careplan'}
                    </StyledDiagnosisCodeDescription>
                  </StyledDiagnosisTitleDiv>
                )}
              </Link>
            </CareplanDetailWrapper>
            {careplan.careplanStatus !== CAREPLAN_STATUS.cpot && careplanCanBeVoidedOrDeleted && (
              <div onClick={handleClickDelete}>
                <Delete id={`dd-delete-${careplan.id}`} show={careplanHovered || isPopUpOpen} />
              </div>
            )}
          </StyledPatientNavContainer>
        </StyledCareplanListItem>
      </Tooltip>
      <DeleteCareplan
        patientId={patientId}
        careplan={careplan}
        handlePopUpClose={handleClosePopUp}
        anchorEl={anchorEl}
        isPopUpOpen={isPopUpOpen}
      />
    </>
  );
};

const ROSidePatientNav = (): JSX.Element => {
  const match = useRouteMatch<ROPatientDashboardNavType>();
  const { id: patientId } = match.params;
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const theme = useTheme();
  const createCareplanClick = (): void => {
    history.push(`/radiation/patient/${patientId}/careplan/create`);
  };
  const { data: careplanData, error: careplanDataError } = useQuery(GET_PATIENT_CAREPLAN_LIST, {
    variables: { patientId },
    fetchPolicy: 'network-only',
  });

  const showOutcomes = CurrentAppConfig.OutcomesPage.isVisible;
  const showOTR = CurrentAppConfig.OTRPage.isVisible;

  useEffect(() => {
    if (careplanDataError) return setError();
  }, [careplanDataError]);

  return (
    <Fragment>
      <Stack sx={{ alignItems: 'center' }}>
        <PatientEMR patientId={patientId} />
        <StyledNavLinksContainer>
          <Stack className="ro-side-patient-nav px-0">
            {[...menuOptions]
              .filter((option) => option.isVisible)
              .map((option) =>
                option.isExternal ? (
                  <ExternalNavLinks option={option} key={option.label} />
                ) : (
                  <PatientNavLinks option={option} key={option.label} />
                ),
              )}
          </Stack>
        </StyledNavLinksContainer>
        <StyledQuickAccessRibbon>QUICK ACCESS</StyledQuickAccessRibbon>
        <Button
          id="pts-create-careplan-button"
          data-testid="pts-create-careplan-button"
          variant="outlined"
          startIcon={<ControlPoint color="primary" />}
          style={{
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '20px',
            borderWidth: '2px',
          }}
          sx={{
            backgroundColor: theme.palette.primary.contrastText,
          }}
          onClick={(): void => {
            createCareplanClick();
          }}>
          <Typography variant="h6">Create careplan</Typography>
        </Button>
        <StyledCareplansHeader>Careplans</StyledCareplansHeader>
        <StyledScrollableRow>
          {careplanData &&
            careplanData.careplanList
              .filter((careplan: Careplan) => careplan.careplanStatus !== CAREPLAN_STATUS.voided)
              .map(
                (careplan: Careplan, index: number): JSX.Element => (
                  <CareplanNavLinks careplan={careplan} patientId={patientId} key={careplan.id} index={index} />
                ),
              )}
        </StyledScrollableRow>
      </Stack>
    </Fragment>
  );
};

export default ROSidePatientNav;
