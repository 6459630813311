// Packages
// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';

// Types
import { GraphUser } from '../Login/Helper';

// Components
import { LoadingSpinner } from 'shared-components/components';

export const VERIFY_RESET_PASSWORD = gql`
  mutation VerifyResetPasswordToken($token: String!) {
    verifyResetPasswordToken(token: $token) {
      mfaDebugToken
      user {
        id
        username
        lastThreeMobile
      }
      errors
    }
  }
`;

const RESET_TOKEN_PARAM = 'token';

interface Props extends WithApolloClient<{}>, RouteComponentProps {}

interface State {
  error: boolean;
  loading: boolean;
}

class ResetPassword extends Component<Props, State> {
  private resetToken: string | null;
  private mfaDebugToken?: string;
  private user?: GraphUser;

  public constructor(props: Props) {
    super(props);

    this.state = {
      error: false,
      loading: true,
    };

    const searchParams = new URLSearchParams(this.props.location.search);
    this.resetToken = searchParams.get(RESET_TOKEN_PARAM);
  }

  public componentDidMount(): void {
    const { client } = this.props;

    if (!client) {
      throw new Error('Apollo client not found');
    }
    if (this.resetToken) {
      client
        .mutate({
          mutation: VERIFY_RESET_PASSWORD,
          variables: {
            token: this.resetToken,
          },
        })
        .then((result) => {
          if (result.data && result.data.verifyResetPasswordToken) {
            const { mfaDebugToken, user } = result.data.verifyResetPasswordToken;

            // Part of debug workflow. If it's returned, use it, doesn't require a guard.
            this.mfaDebugToken = mfaDebugToken;

            if (user) {
              this.user = user;
              this.setState({ error: false, loading: false });
            } else {
              this.setState({ error: true, loading: false });
            }
          }
        });
    }
  }

  public render(): JSX.Element {
    // Default state as error/expired link
    let redirectionParams: {} = {
      pathname: '/px/forgotPassword',
      state: {
        expiredLink: true,
      },
    };

    // Verifying by default
    if (this.state.loading) {
      return (
        <div>
          <LoadingSpinner loadingText={'Verifying your password reset'} />
        </div>
      );
    }

    // Finished verifying with no errors
    if (!this.state.error && !this.state.loading) {
      redirectionParams = {
        pathname: '/px/mfa/',
        state: {
          next: '/px/home',
          mfaToken: this.mfaDebugToken,
          user: this.user,
          resetPassword: true,
          resetToken: this.resetToken,
        },
      };
    }

    return <Redirect from="/" to={redirectionParams} />;
  }
}

//@ts-ignore
const apolloWrappedComponent = withApollo(ResetPassword);
//@ts-ignore
export default withRouter(apolloWrappedComponent);
