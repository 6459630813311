import React, { useState, useCallback } from 'react';
import { ROPatientContextProvider } from '../PatientSummary/context';
import OuterContainer from '../PatientSummary/OuterContainer';
import { styled } from '@mui/system';

import { Card } from 'shared-components/components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import WarningFilledIcon from '@mui/icons-material/Warning';
import { Grid, Box, Typography, useTheme, CustomTheme } from '@mui/material';
import { PatientCheckboxes } from '../Dashboard/PatientTracker/PatientTable';
import { patientData as fullPatientData } from './../Dashboard/PatientTracker/PatientData';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';

const PathwayWrapper = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
`;

const StyledPageHeader = styled('h2')`
  font-size: 23px;
  color: #081e43;
  margin-bottom: 16px;
`;

const HeaderRow = styled(TableRow)`
  &.MuiTableRow-root {
    border-width: 1px;
  }
`;
const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-weight: 600;
    text-align: center;
    max-width: 120px;
  }
`;

const DataCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-weight: 400;
    text-align: center;
  }
`;

interface ROPatientPathwayParams {
  id: string;
}

interface FoldableCellProps {
  keyIndex: number;
  value: string;
  hideData: boolean;
  onHideCallback: (index: number, hide: boolean) => void;
}

interface DetailsCellProps {
  keyIndex: number;
  name: string;
  date: Date;
  status: string;
  alert: boolean;
}

const patientData = fullPatientData[3];
const FoldableCell = (props: FoldableCellProps) => {
  const { keyIndex, value, hideData, onHideCallback } = props;
  return (
    <HeaderCell
      style={{
        minWidth: '270px',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderRightColor: 'rgba(224, 224, 224, 1)',
      }}
      key={`header-cell-${keyIndex}`}>
      <div style={{ display: 'flex' }} onClick={() => onHideCallback(keyIndex, !hideData)}>
        {hideData ? <ChevronRightIcon /> : <ExpandMoreIcon />}
        {value}
      </div>
    </HeaderCell>
  );
};

const statusToColor = (status: string): string => {
  switch (status) {
    case 'Completed':
      return '#3EC490';
    case 'In progress':
      return '#C55011';
    case 'Not started':
      return '#081e43';
    default:
      return '#F8F8F8';
  }
};

const StyledChip = styled(Chip)<{ statusColor: string }>`
  &.MuiChip-outlined {
    border-color: ${(props) => props.statusColor};
    color: ${(props) => props.statusColor};
  }
`;

const DetailsCell = (props: DetailsCellProps) => {
  const { keyIndex, name, date, status, alert } = props;
  const theme: CustomTheme = useTheme();
  return (
    <DataCell
      style={{ borderRightWidth: '1px', borderRightStyle: 'solid', borderRightColor: 'rgba(224, 224, 224, 1)' }}
      key={keyIndex}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body2" style={{ color: 'rgba(8,30,67, 0.6)' }}>
          {dateToString(date)}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '16px',
          }}>
          <StyledChip
            label={status}
            variant="outlined"
            statusColor={statusToColor(status)}
            style={{ width: 'fit-content' }}
          />
          {alert && <WarningFilledIcon style={{ color: theme.palette.statusColors.simulation }} />}
        </div>
      </div>
    </DataCell>
  );
};

const dateToString = (date: Date): string => {
  return date ? moment(date).format('MMM-DD-YYYY, h:mm a') : '-';
};

const DataTable = ({
  header,
  data,
  hideData,
  keyIndex,
  onHideCallback,
  numberOfColumns,
}: {
  header: string[];
  data: any[];
  hideData: boolean;
  keyIndex: number;
  onHideCallback: (index: number, hide: boolean) => void;
  numberOfColumns: number;
}) => {
  const numberOfDataColumns = data.length;
  return (
    <>
      <HeaderRow key={`row-${2 * keyIndex}`}>
        {header.map((column, index) =>
          index === 0 ? (
            <FoldableCell keyIndex={keyIndex} value={column} hideData={hideData} onHideCallback={onHideCallback} />
          ) : (
            <HeaderCell key={index}>{column}</HeaderCell>
          ),
        )}
        {Array(numberOfColumns - numberOfDataColumns)
          .fill(0)
          .map((_, index) => (
            <DataCell key={`empty-${index}`}></DataCell>
          ))}
      </HeaderRow>
      {!hideData && (
        <TableRow key={`row-${2 * keyIndex + 1}`}>
          {data.map((column, index) =>
            index === 0 ? (
              <DetailsCell keyIndex={`col-${index}`} {...column} />
            ) : (
              <DataCell>
                <PatientCheckboxes
                  enableTooltip={column.date !== '-'}
                  {...column}
                  treatmentWeek={index}
                  patientData={patientData}
                />
              </DataCell>
            ),
          )}
          {Array(numberOfColumns - numberOfDataColumns)
            .fill(0)
            .map((_, index) => (
              <DataCell key={`empty-${index}`}></DataCell>
            ))}
        </TableRow>
      )}
    </>
  );
};

const GET_PATIENT_INFO = gql`
  query patient($id: ID!) {
    patient(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

const ROPatientPathway = (): JSX.Element => {
  const match = useRouteMatch<ROPatientPathwayParams>();
  const { id: patientId } = match.params;
  const [rowHide, setRowHide] = useState([false, false, false]);
  const { data: patientInfo } = useQuery(GET_PATIENT_INFO, {
    variables: { id: patientId },
  });
  const onHideCallback = useCallback(
    (index: number, hide: boolean) => {
      setRowHide((rowHide) => {
        rowHide[index] = hide;
        return [...rowHide];
      });
    },
    [setRowHide],
  );

  const careplan = patientData.careplanName;
  const headerInfo: { [key: string]: string[] } = {
    planning: [
      'PLANNING',
      'Initial Consult',
      'Pre-Auth',
      'SIM Request',
      'Prescription',
      'Compliance Documents',
      'Simulation',
      'Fusion',
      'Target Volume',
      'Contouring',
      'Plan Approval',
    ],
    onTreatmentReview: ['ON TREATMENT REVIEW', 'Week 1', 'Week 2', 'Week 3', 'Week 4', 'End of Tx'],
    outcomes: ['OUTCOMES', '3-6 months', '6-9 months', '12 months', '1-2 years', '2 years', '3 years', '5 years'],
  };
  const today = dayjs().startOf('day');
  const data: any[any] = {
    planning: [
      {
        name: 'Initial Consult',
        date: today.subtract(16, 'day').add(9, 'hour').format('YYYY-MM-DDTHH:mm'),
        status: 'Completed',
      },
      { tooltipType: 'initialConsult', date: today.subtract(16, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'preAuth', date: today.subtract(15, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'SIMRequest', date: today.subtract(14, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'prescription', date: today.subtract(14, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'complianceDocs', date: today.subtract(14, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'simulation', date: today.subtract(11, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'fusion', date: today.subtract(10, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'targetVolume', date: today.subtract(8, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'contouring', date: today.subtract(8, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
      { tooltipType: 'planApproval', date: today.subtract(7, 'day').format('MMM-DD-YYYY'), status: 'Completed' },
    ],
    onTreatmentReview: [
      {
        name: 'First treatment',
        date: today.add(10, 'hour').format('YYYY-MM-DDTHH:mm'),
        status: 'In progress',
        alert: true,
      },
      { tooltipType: 'treatment', date: 'Due today', status: 'Due today' },
      { tooltipType: 'treatment', date: today.add(7, 'day').format('MMM-DD-YYYY'), status: 'To do' },
      { tooltipType: 'treatment', date: today.add(14, 'day').format('MMM-DD-YYYY'), status: 'To do' },
      { tooltipType: 'treatment', date: today.add(21, 'day').format('MMM-DD-YYYY'), status: 'To do' },
      { tooltipType: 'treatment', date: today.add(28, 'day').format('MMM-DD-YYYY'), status: 'To do' },
    ],
    outcomes: [
      { name: 'Pending', date: '', status: 'Not started' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
      { date: '-', status: 'To do' },
    ],
  };
  const numberColumns = Math.max(data.planning.length, data.onTreatmentReview.length, data.outcomes.length);

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <PathwayWrapper>
          <Grid xs={12} style={{ height: '100%' }}>
            <Card className="d-flex flex-column" $filled>
              <StyledPageHeader>Patient Comprehensive Pathway</StyledPageHeader>
              <Box style={{ overflowX: 'auto', height: '100%' }}>
                <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                  <Table>
                    <TableBody>
                      {Object.keys(headerInfo).map((key: string, index: any) => {
                        return (
                          <DataTable
                            keyIndex={index}
                            header={headerInfo[key]}
                            data={data[key]}
                            hideData={rowHide[index]}
                            onHideCallback={onHideCallback}
                            numberOfColumns={numberColumns}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Card>
          </Grid>
        </PathwayWrapper>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default ROPatientPathway;
