import React from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import Table from 'shared-components/components/Table/Table';
import { ariaRounding, groupBy } from 'op-utils';
import { LoadingSpinner } from 'shared-components/components';
import { GET_DOSE_SITE_SUMMARIES } from '../PatientProfileQueries';
import { useErrorModalContext } from 'op-contexts';
import { IDose, ITitleComponent, IFormattedDose } from './interfaces';
import { useTheme } from '@mui/material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const TitleStatus = styled('div')<any>`
  line-height: 24px;
  ${(props: any) => props.$grouped && 'padding-left: 16px;'}
`;

const StatusContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const StatusCircle = styled('div')<any>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ statusColor }: { statusColor: string }) => `${statusColor}`};
  margin-right: 2px;
`;

const tableHeaders = [
  { title: 'Dose site summary', key: 'title', type: 'string', width: '26%' },
  { title: 'Course', key: 'course', type: 'string', width: '11%' },
  { title: 'Delivered', key: 'delivered', type: 'string', width: '11%' },
  { title: 'Prescribed', key: 'prescribed', type: 'string', width: '11%' },
  { title: 'Start Tx', key: 'treatmentStart', type: 'string', width: '11%' },
  { title: 'Latest Tx', key: 'treatmentLatest', type: 'string', width: '11%' },
  { title: 'Final Tx', key: 'treatmentFinal', type: 'string', width: '11%' },
  { title: 'Source', key: 'source', type: 'string', width: '8%' },
];

export const display = (field: string | null): string => (field ? field : '-');

export const sortByNewestToOldest = (input: any): any => {
  // Depends on sortDate
  return [...input].sort((a: any, b: any) =>
    moment(b.sortDate, 'DD/MM/YY').diff(moment(a.sortDate, 'DD/MM/YY'), 'days'),
  );
};

export const computeGroupTotal = (doses: Array<any>): any => {
  // All cards should have the same TotalDoseLimit. We will just take the first one
  const totalDoseLimit = ariaRounding(doses[0].totalDosageLimit);

  let totalDeliveredDose = 0.0;
  let totalDoseMax = 0.0;
  doses.forEach((dose: any) => {
    totalDeliveredDose += dose.deliveredDosageQuantity;
    totalDoseMax += dose.plannedDosageQuantity;
  });
  totalDeliveredDose = ariaRounding(totalDeliveredDose);
  totalDoseMax = ariaRounding(totalDoseMax);

  return {
    totalDeliveredDose: totalDeliveredDose === totalDoseMax ? totalDoseMax : totalDoseLimit,
    totalDoseMax: totalDoseMax,
  };
};

const createDoseGroup = (groupName: string, doses: Array<any>) => {
  const doseGroupData = sortByNewestToOldest(doses);
  const showTotal = doseGroupData.length > 1;
  const { totalDeliveredDose, totalDoseMax } = computeGroupTotal(doseGroupData);

  return {
    groupName: groupName,
    totalDeliveredDose: totalDeliveredDose,
    totalDoseMax: totalDoseMax,
    isAria: true,
    sortDate: doseGroupData[0].sortDate,
    showTotal: showTotal,
    doseGroupCards: doseGroupData,
  };
};

const DoseSiteSummaryApollo = (): JSX.Element => {
  const match = useRouteMatch<any>();
  const { setError } = useErrorModalContext();
  const { id: patientId } = match.params;
  const { data, loading, error } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  });
  if (loading) return <LoadingSpinner loadingText={'Loading dose site summaries'} relativeSpinner={true} />;
  if (error) {
    setError('NON_BLOCKING');
    return <DoseSiteSummary doseSiteSummaries={[]} />;
  }
  const doseSiteSummaries = data?.doseSiteSummaries || [];
  return <DoseSiteSummary doseSiteSummaries={doseSiteSummaries} />;
};

const DoseSiteSummary = ({ doseSiteSummaries }: any): JSX.Element => {
  const theme = useTheme();
  const allDoses: any = [];
  const mqDoses = doseSiteSummaries
    .filter((dss: IDose) => !dss.isAria)
    .map((element: any) => {
      return {
        ...element,
        sortDate: moment(element.latestTreatment).format(CurrentAppConfig.DoseSiteSummary.DateFormat),
      };
    });

  const ariaDoses = doseSiteSummaries
    .filter((dss: IDose) => dss.isAria)
    .map((element: any) => {
      return {
        ...element,
        sortDate: moment(element.latestTreatment).format(CurrentAppConfig.DoseSiteSummary.DateFormat),
      };
    });

  const groupedDose = groupBy(ariaDoses, 'categorySiteGroup');
  const formattedAria: Array<any> = [];
  Object.entries(groupedDose).forEach((element: any) => {
    formattedAria.push(createDoseGroup(element[0], element[1]));
  });

  const getStatusColor = (status: string): string =>
    status.toUpperCase() === 'IN PROGRESS'
      ? theme.palette.warning.dark
      : status.toUpperCase() === 'COMPLETE'
      ? theme.palette.primary.main
      : theme.palette.secondary.dark;

  const TitleComponent = ({ title, status, grouped }: ITitleComponent): JSX.Element => (
    <TitleStatus $grouped={grouped}>
      <div>{display(title)}</div>
      {status && (
        <StatusContainer>
          <StatusCircle statusColor={getStatusColor(status)} />
          {status}
        </StatusContainer>
      )}
    </TitleStatus>
  );

  const getAriaStatus = (dose: IDose) => {
    const remainingDose = dose?.remainingDoseQuantity || 0;
    let status = 'In progress';

    if (dose.statusReason === 'CompletedEarly') {
      status = 'Completed early';
    } else if (remainingDose === 0.0) {
      status = 'Complete';
    }
    return status;
  };

  const formatDate = (date: string) => {
    return date ? moment(date).format(CurrentAppConfig.DoseSiteSummary.DateFormat) : '-';
  };

  const TableData = ({ data }: { data: string | null }): JSX.Element => <div>{display(data)}</div>;

  const generateDoseRow = (dose: IFormattedDose) => {
    const {
      title,
      status,
      course,
      delivered,
      prescribed,
      treatmentStart,
      treatmentLatest,
      treatmentFinal,
      source,
      grouped,
    } = dose;

    const doseObject = {
      rows: [
        {
          key: 'title',
          component: <TitleComponent title={title} status={status} grouped={grouped} />,
          width: '26%',
        },
        { key: 'course', component: <TableData data={course} />, width: '11%' },
        {
          key: 'delivered',
          component: <TableData data={delivered} />,
          width: '11%',
        },
        {
          key: 'prescribed',
          component: <TableData data={prescribed} />,
          width: '11%',
        },
        { key: 'treatmentStart', component: <TableData data={treatmentStart} />, width: '11%' },
        { key: 'treatmentLatest', component: <TableData data={treatmentLatest} />, width: '11%' },
        {
          key: 'treatmentFinal',
          component: <TableData data={treatmentFinal} />,
          width: '11%',
        },
        {
          key: 'source',
          component: <TableData data={source} />,
          width: '8%',
        },
      ],
    };

    if (status?.toUpperCase() === 'IN PROGRESS') {
      //@ts-ignore
      doseObject['rowBackgroundColor'] = theme.palette.warning.light;
    }

    allDoses.push(doseObject);
  };

  const generateAriaDoseRow = ({ dose, grouped }: { dose: IDose; grouped?: boolean }) => {
    const actualDose = dose.deliveredDosageQuantity || 0;
    const remainingDose = dose.remainingDoseQuantity || 0;
    const totalDose = actualDose + remainingDose;
    const actualFraction = dose.deliveredDosageRatio || 0;
    const totalFraction = dose.plannedDosageRatio || 0;
    const status = getAriaStatus(dose);
    const lastTreatment = dose.latestTreatment ? formatDate(dose.latestTreatment) : null;
    const finalTx = status.startsWith('Complete') ? lastTreatment : 'See Appointments';

    const attr = {
      title: dose?.identifierSite,
      course: dose?.medicationReference,
      delivered: `${actualDose}Gy/${actualFraction}#`,
      prescribed: `${ariaRounding(totalDose)}Gy/${totalFraction}#`,
      treatmentStart: dose.firstTreatment ? formatDate(dose.firstTreatment) : null,
      treatmentLatest: lastTreatment,
      treatmentFinal: finalTx,
      source: 'Aria',
      status: status,
      grouped: grouped,
    };
    generateDoseRow(attr);
  };

  mqDoses.forEach((dose: any) => {
    const actualDose = dose.deliveredDosageQuantity / 100;
    const totalDose = dose.plannedDosageQuantity / 100;
    const actualFraction = dose.deliveredDosageRatio || 0;
    const totalFraction = dose.plannedDosageRatio || 0;
    const status = actualFraction === totalFraction ? 'Complete' : 'In progress';
    const lastTreatment = formatDate(dose.latestTreatment);
    const finalTx = status.startsWith('Complete') ? lastTreatment : 'See Appointments';

    const attr = {
      title: dose.identifierSite,
      status: status,
      course: dose?.medicationReference,
      delivered: `${actualDose}Gy/${actualFraction}#`,
      prescribed: `${totalDose}Gy/${totalFraction}#`,
      treatmentStart: formatDate(dose.firstTreatment),
      treatmentLatest: formatDate(dose.latestTreatment),
      treatmentFinal: finalTx,
      source: 'Mosaiq',
    };
    generateDoseRow(attr);
  });

  formattedAria.forEach((group: any) => {
    if (group.doseGroupCards.length > 1) {
      const attr = {
        title: group?.groupName,
        course: display(null),
        delivered: group?.showTotal && group?.totalDeliveredDose && `${group.totalDeliveredDose}Gy`,
        prescribed: group?.showTotal && group?.totalDoseMax && `${group.totalDoseMax}Gy`,
        treatmentStart: display(null),
        treatmentLatest: display(null),
        treatmentFinal: display(null),
        source: 'Aria',
      };
      generateDoseRow(attr);

      group.doseGroupCards.forEach((dose: IDose) => {
        generateAriaDoseRow({ dose, grouped: true });
      });
    } else {
      const dose = group.doseGroupCards[0];
      generateAriaDoseRow({ dose });
    }
  });

  const tableData = allDoses.map((dose: any) => {
    return {
      tableRows: dose.rows,
      ...dose,
    };
  });
  return <Table tableHeaders={tableHeaders} tableData={tableData} noRowsText="Treatment has not started yet." />;
};

export default DoseSiteSummaryApollo;
